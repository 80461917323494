import { type ReactNode } from 'react';
import { Badge, Button, Popup } from '@repo/design-system-kit';
import { FormattedMessage } from '@repo/domain-kit/shared';
import { useSegment } from '@repo/poly-hooks/segment';
import { useIntl } from 'react-intl';
import ENV from 'qonto/config/environment';
import { EsignaturePromoIllustration } from 'qonto/react/receivable-invoices/components/promotions/esignature-promo-popup/esignature-promo-illustration.tsx';

interface ESignaturePromoPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  hasFreeTrial?: boolean;
}

const isTest = ENV.environment === 'test';

export function ESignaturePromoPopup({
  isOpen,
  onClose,
  onConfirm,
  hasFreeTrial = false,
}: ESignaturePromoPopupProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useSegment();

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER =
    document.getElementById('ember-testing') ?? document.querySelector('main') ?? undefined;

  const handleClick = (): void => {
    segment.track('ar_add_on-feature_modal-clicked', {
      cta_type: hasFreeTrial ? 'free_trial' : 'upgrade',
      feature: 'e-signature',
    });
    onConfirm();
  };

  return (
    <Popup.Root
      data-test-id="quotes.e-signature-promotional-popup"
      isOpen={isOpen}
      portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
    >
      <Popup.CloseButton onPress={onClose} />
      <Popup.Illustration isPromotional>
        <EsignaturePromoIllustration hasFreeTrial={hasFreeTrial} />
      </Popup.Illustration>
      <Popup.Badge data-test-id="quotes.e-signature-promotional-popup.badge">
        {hasFreeTrial ? (
          <Badge
            text={formatMessage({ id: 'promotion-system.badge.trial.label' })}
            type="tryForFree"
          />
        ) : (
          <Badge text={formatMessage({ id: 'upsell.add-ons.badge' })} type="upgrade" />
        )}
      </Popup.Badge>
      <Popup.Header>
        <Popup.Title data-test-id="quotes.e-signature-promotional-popup.title">
          <FormattedMessage id="quotes.e-signature.feature-popup.title" />
        </Popup.Title>
      </Popup.Header>
      <Popup.Subtitle data-test-id="quotes.e-signature-promotional-popup.body">
        <p className="body-1" data-test-id="modal-body">
          <FormattedMessage id="quotes.e-signature.feature-popup.body" />
        </p>
      </Popup.Subtitle>
      <Popup.Footer>
        <Button
          data-test-id="quotes.e-signature-promotional-popup-cta"
          onPress={handleClick}
          variant="primary"
        >
          {hasFreeTrial ? (
            <FormattedMessage id="upsell.discover.trial.confirm.cta" />
          ) : (
            <FormattedMessage id="upsell.add-ons.cta" />
          )}
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
