import { type ReactNode } from 'react';
import { clsx } from 'clsx';
import { Button } from '@repo/design-system-kit';
import { IconChevronLeftOutlined, IconChevronRightOutlined } from '@repo/monochrome-icons';
import { useIntl } from 'react-intl';
import styles from './q-page-selector.strict-module.css';

interface QPageSelectorProps {
  currentPage: number;
  totalCount: number;
  totalPages: number;
  perPage: number;
  paginationLabel?: string;
  changePerPage?: (perPage: number) => void;
  changePage?: (page: number) => void;
}

const perPageOptions = [25, 50, 100];

export function QPageSelector({
  currentPage,
  totalCount,
  totalPages,
  perPage,
  paginationLabel = 'of',
  changePerPage,
  changePage,
  ...props
}: QPageSelectorProps): ReactNode {
  const { formatMessage } = useIntl();
  const previousAvailable = currentPage > 1;
  const nextAvailable = currentPage < totalPages;

  const offset = currentPage * perPage;
  const end = Math.min(offset, totalCount);
  const start = totalCount && offset - perPage + 1;
  const summary = `${start}-${end} ${paginationLabel} ${totalCount}`;

  const handlePrevious = (): void => {
    if (previousAvailable && changePage) {
      changePage(currentPage - 1);
    }
  };

  const handleNext = (): void => {
    if (nextAvailable && changePage) {
      changePage(currentPage + 1);
    }
  };

  return (
    <div className={styles.wrapper} data-test-q-page-selector {...props}>
      {changePerPage ? (
        <div className={styles['per-page-container']}>
          <div className={clsx(styles.options, 'body-2')}>
            {perPageOptions.map(option => (
              <button
                aria-label={formatMessage(
                  { id: 'labels.items_per_page_count' },
                  { number: option }
                )}
                className={clsx(styles.option, perPage === option && styles.active)}
                data-test-change-per-page-button={option}
                key={option}
                onClick={() => {
                  changePerPage(option);
                }}
                type="button"
              >
                {option}
              </button>
            ))}
          </div>
          <div className={clsx(styles.description, styles['description-per-page'], 'body-2')}>
            {formatMessage({ id: 'labels.items_per_page' })}
          </div>
        </div>
      ) : null}
      <div
        className={clsx(styles.description, styles.summary, 'body-2')}
        data-test-q-page-selector-count-summary
      >
        {summary}
      </div>
      <div className={styles.options}>
        <Button
          aria-label={formatMessage({ id: 'labels.previous_page' })}
          className={styles.option}
          data-test-page-selection-previous-button
          iconOnly
          isDisabled={!previousAvailable}
          onPress={handlePrevious}
          variant="tertiary"
        >
          <IconChevronLeftOutlined />
        </Button>
        <Button
          aria-label={formatMessage({ id: 'labels.next_page' })}
          className={styles.option}
          data-test-page-selection-next-button
          iconOnly
          isDisabled={!nextAvailable}
          onPress={handleNext}
          variant="tertiary"
        >
          <IconChevronRightOutlined />
        </Button>
      </div>
    </div>
  );
}
