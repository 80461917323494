import type { QueryClient, QueryKey } from '@tanstack/react-query';
import { SEARCH_TRANSACTIONS_QUERY_KEY } from 'qonto/react/hooks/use-search-transactions';

export const getLatestTransactionQueryKey = (
  queryClient: QueryClient,
  organizationId: string
): QueryKey | undefined => {
  const queryKeys = queryClient.getQueryCache().findAll({
    queryKey: [SEARCH_TRANSACTIONS_QUERY_KEY, organizationId],
    exact: false,
  });

  return queryKeys
    .filter(query => query.state.status === 'success')
    .sort((a, b) => b.state.dataUpdatedAt - a.state.dataUpdatedAt)[0]?.queryKey;
};
