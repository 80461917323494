import type { ReactNode } from 'react';
import { IconCheckmarkOutlined } from '@repo/monochrome-icons';
import styles from './styles.strict-module.css';

interface CheckmarkListItemProps {
  children: ReactNode;
}

export function CheckmarkListItem({ children }: CheckmarkListItemProps): ReactNode {
  return (
    <li className={styles['list-item']}>
      <IconCheckmarkOutlined aria-hidden="true" className={styles.checkmark} />
      {children}
    </li>
  );
}
