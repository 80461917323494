import { useCallback, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { Message, Participant, Transaction, Thread } from 'qonto/react/graphql';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { useToggleReadStatus } from './use-toggle-read-status';

export const useThread = (
  transaction: Transaction
): {
  read: boolean;
  count: number;
  messages: Message[];
  participants: Participant[];
  currentUserIsParticipant: boolean;
  userId: string;
  toggleReadStatus: () => void;
} => {
  const { membership } = useOrganizationManager();
  const { membershipId } = membership;
  const { thread, note, initiator } = transaction;
  const { formatMessage } = useIntl();
  const currentParticipant = thread?.participants.find(
    participant => participant.membershipId === membershipId
  );

  const messages = thread?.messages.toReversed() ?? [];

  const [read, setRead] = useState(isThreadRead(thread, currentParticipant));
  const { mutate } = useToggleReadStatus();
  const toggleReadStatus = useCallback((): void => {
    if (!thread) throw Error('A thread is required to toggle read status');

    setRead(!read);
    mutate(
      { threadId: thread.id, read },
      {
        onError: () => {
          setRead(read);
        },
      }
    );
  }, [read, thread, mutate]);

  const noteMessage = useMemo(() => {
    if (!note) return null;
    const authorName = formatMessage({
      id: 'transactions.modular_table.comments.additional_information.label',
    });
    return createMessageFromNote(note, authorName);
  }, [note, formatMessage]);

  const messagesWithNote = noteMessage ? [noteMessage, ...messages] : messages;
  let participants = thread?.participants ?? [];

  if (!participants.length && initiator) {
    participants = [
      {
        id: '',
        membershipId: initiator.id,
        lastReadAt: null,
        membership: initiator,
      },
    ];
  }

  return {
    read,
    count: messagesWithNote.length,
    messages: messagesWithNote,
    participants,
    currentUserIsParticipant: Boolean(currentParticipant),
    userId: membershipId,
    toggleReadStatus,
  };
};

function isThreadRead(
  thread: Thread | null | undefined,
  currentParticipant: Participant | undefined
): boolean {
  if (!thread?.messages[0]) return true;
  return new Date(currentParticipant?.lastReadAt ?? '') > new Date(thread.messages[0].createdAt);
}

function createMessageFromNote(note: string, membershipFullname: string): Message {
  return {
    id: 'transaction-note',
    content: note,
    createdAt: '',
    author: {
      id: '',
      lastReadAt: null,
      membershipId: '',
      membership: {
        fullName: membershipFullname,
        role: '',
        email: '',
        avatar: {
          url: '',
        },
        avatarThumb: {
          url: '',
        },
        firstName: '',
        id: '',
        lastName: '',
        team: {
          id: '',
          name: '',
        },
        teamId: '',
      },
    },
  };
}
