import { useQuery } from '@tanstack/react-query';
import { useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { cashFlowCategoriesNamespaceV2 } from 'qonto/constants/hosts';
import ENV from 'qonto/config/environment';
import type { CashFlowCategoriesForecastsPreviewDto } from '../api/models/cash-flow-forecast-preview';
import type { CashFlowCategoriesForecastsPreview } from '../models/cash-flow-categories-forecasts-preview';
import type { QueryResult } from '../api/utils/query-result';
import { useFetchApi } from './use-fetch-api';

const MOCK_RESPONSE = {
  categories: {
    '0195c268-72f0-792d-87e5-c6d4897931b0': {
      '2025-04': {
        value: '100.00',
        currency: 'EUR',
      },
      '2025-05': {
        value: '120.00',
        currency: 'EUR',
      },
      '2025-06': {
        value: '140.00',
        currency: 'EUR',
      },
      '2025-07': {
        value: '150.00',
        currency: 'EUR',
      },
      '2025-08': {
        value: '180.00',
        currency: 'EUR',
      },
      '2025-09': {
        value: '200.00',
        currency: 'EUR',
      },
      '2025-10': {
        value: '1200.00',
        currency: 'EUR',
      },
      '2025-11': {
        value: '1300.00',
        currency: 'EUR',
      },
      '2025-12': {
        value: '1050.00',
        currency: 'EUR',
      },
      '2026-01': {
        value: '1600.00',
        currency: 'EUR',
      },
      '2026-02': {
        value: '1500.00',
        currency: 'EUR',
      },
      '2026-03': {
        value: '10000.00',
        currency: 'EUR',
      },
    },
  },
};

export const useCashflowFlashForecastPreviews =
  (): QueryResult<CashFlowCategoriesForecastsPreview> => {
    const { featureBooleanCfmFlashForecasts } = useFlags();

    const fetchApi = useFetchApi();
    const fetchFlashForecastPreviews = async (): Promise<
      CashFlowCategoriesForecastsPreview | undefined
    > => {
      if (!featureBooleanCfmFlashForecasts) {
        return;
      }

      const endpointUrl = `${cashFlowCategoriesNamespaceV2}/cash-flow/timeseries/forecasts/auto-fill/preview`;
      const response = await fetchApi(endpointUrl, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Error fetching cashflow forecast previews');
      }

      return (await response.json()) as CashFlowCategoriesForecastsPreviewDto;
    };

    const {
      data,
      isLoading: isPending,
      isError,
      refetch,
    } = useQuery({
      queryKey: ['cashflow-timeseries-flash-forecasts'],
      queryFn: fetchFlashForecastPreviews,
      staleTime: 1000 * 60, // 1 min
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      retry: ENV.environment === 'test' ? false : 3,
      initialData: MOCK_RESPONSE, // until BE is ready
    });

    return {
      data,
      isPending,
      isError,
      refetch,
    };
  };
