import React from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import { Tag } from '@repo/design-system-kit';
import { IconInvoiceOutlined } from '@repo/monochrome-icons';
import {
  EINVOICING_STATUS,
  INVOICING_STATUS_COLORS,
  STATUS,
} from 'qonto/constants/receivable-invoice';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { formatMoney } from 'qonto/react/shared/utils/format-money';
import styles from './styles.strict-module.css';

interface Document {
  id: string;
  status: string;
  displayedStatus: string;
  amountDue: number;
  currency: string;
  number: string;
  einvoicingStatus?: string;
  isDeposit?: boolean;
  receivableCreditNotes?: CreditNote[];
}

interface CreditNote {
  amountDue: number;
}

interface RelatedDocumentProps {
  document?: Document;
  isReadOnly?: boolean;
  showSign?: boolean;
  hideStatusBadge?: boolean;
  documentType?: 'quote' | 'invoice' | 'credit-note';
}

export function RelatedDocument({
  document,
  isReadOnly = false,
  showSign = false,
  hideStatusBadge = false,
  documentType = 'invoice',
}: RelatedDocumentProps): React.ReactNode {
  const intl = useIntl();
  const { organization } = useOrganizationManager();

  if (!document) {
    return null;
  }

  const creditNotes = document.receivableCreditNotes || [];
  const showStatusBadge = documentType === 'invoice' && !hideStatusBadge;
  const currency = document.currency || 'EUR';
  const isCanceled = document.status === 'canceled' && !showSign;
  const showNegativeSign = showSign && document.amountDue < 0;
  const showPositiveSign = showSign && document.amountDue >= 0;
  const isRejected = [EINVOICING_STATUS.SUBMISSION_FAILED, EINVOICING_STATUS.REJECTED].includes(
    document.einvoicingStatus || ''
  );
  const isInvoiceWithCreditNote =
    documentType === 'invoice' && creditNotes.length > 0 && document.status === STATUS.PAID;

  const getCreditNotesAmount = (): number => {
    return creditNotes.length > 0
      ? creditNotes.reduce(
          (accumulator, currentValue) => accumulator + Number(currentValue.amountDue),
          0
        )
      : 0;
  };

  const creditNotesAmountDisplay = formatMoney(getCreditNotesAmount(), {
    currency,
    locale: intl.locale,
  });

  const invoicedAmountDisplay = formatMoney(Number(document.amountDue), {
    currency,
    locale: intl.locale,
  });

  const getDocumentTitle = (): string => {
    switch (documentType) {
      case 'quote':
        return intl.formatMessage(
          { id: 'receivable-invoice.quote-modal.related-documents.file.quote' },
          { QuoteNumber: document.number }
        );
      case 'invoice':
        if (document.status === STATUS.DRAFT) {
          return intl.formatMessage({
            id: 'receivable-invoices.quote-modal.related-documents.file.draft',
          });
        }
        if (document.isDeposit) {
          return intl.formatMessage(
            { id: 'receivable-invoices.credit-note-modal.related-documents.file.deposit-invoice' },
            { depositInvoiceNumber: document.number }
          );
        }
        return intl.formatMessage(
          { id: 'receivable-invoices.credit-note-modal.related-documents.file.invoice' },
          { invoiceNumber: document.number }
        );
      case 'credit-note':
        return intl.formatMessage(
          { id: 'receivable-invoices.invoice-modal.related-documents.file.credit-note' },
          { creditNoteNumber: document.number }
        );
      default:
        return '';
    }
  };

  const getPathToDocument = (): string => {
    const baseUrl = `/organizations/${organization.slug}`;

    switch (documentType) {
      case 'quote':
        return `${baseUrl}/quotes/${document.id}`;
      case 'invoice':
        return `${baseUrl}/receivable-invoices/${document.id}`;
      case 'credit-note':
        return `${baseUrl}/receivable-invoices/credit-note/${document.id}`;
      default:
        return '';
    }
  };

  const status = {
    displayedStatus: document.displayedStatus || document.status,
    color: INVOICING_STATUS_COLORS[document.status as keyof typeof INVOICING_STATUS_COLORS],
  };

  return (
    <div className={styles.document} data-test-related-document>
      {isReadOnly ? (
        <div className={styles['document-icon']} data-test-document-icon>
          <IconInvoiceOutlined />
        </div>
      ) : (
        <Link
          className={cx(styles['document-icon'], styles.active)}
          data-test-document-icon
          to={getPathToDocument()}
        >
          <IconInvoiceOutlined />
        </Link>
      )}

      <div className={styles['document-details']}>
        {isReadOnly ? (
          <span className={styles['document-details-link']} data-test-document-link>
            {getDocumentTitle()}
          </span>
        ) : (
          <Link
            className={styles['document-details-link']}
            data-test-document-link
            to={getPathToDocument()}
          >
            {getDocumentTitle()}
          </Link>
        )}
        {isInvoiceWithCreditNote ? (
          <p
            className={styles['document-details-amount']}
            data-test-document-amount-with-credit-note
          >
            {intl.formatMessage(
              {
                id: 'receivable-invoice.quote-modal.related-documents.invoiced-amount-with-credit-notes',
              },
              {
                number: creditNotes.length,
                invoiceAmount: invoicedAmountDisplay,
                creditNoteAmount: creditNotesAmountDisplay,
              }
            )}
          </p>
        ) : null}
        {!isInvoiceWithCreditNote && document.status !== 'draft' && (
          <p
            className={cx(
              styles['document-details-amount'],
              isRejected && styles.rejected,
              showNegativeSign && styles.negative,
              showPositiveSign && styles.positive,
              isCanceled && styles.canceled
            )}
            data-test-document-amount
          >
            {formatMoney(Number(document.amountDue), {
              currency,
              locale: intl.locale,
              signus: showSign,
            })}
          </p>
        )}
      </div>
      {showStatusBadge ? (
        <div className={styles['status-badge']}>
          <Tag
            color={status.color as 'green' | 'purple' | 'red' | 'gray' | 'orange'}
            data-test-invoice-status=""
            label={status.displayedStatus}
          />
        </div>
      ) : null}
    </div>
  );
}
