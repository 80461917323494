import { type ReactElement, type ReactNode } from 'react';
import { Dialog, DialogTrigger, Modal, ModalOverlay } from 'react-aria-components';
import { type BaseButtonProps, Button, FullscreenBase } from '@repo/design-system-kit';
import ENV from 'qonto/config/environment';
import styles from './fullscreen-modal-trigger.strict-module.css';

const isTest = ENV.environment === 'test';

export interface FullscreenModalTriggerProps {
  buttonText: string;
  children: ReactNode;
  buttonSize?: BaseButtonProps['size'];
  buttonVariant?: BaseButtonProps['variant'];
  onTriggerClick?: () => void;
}

/**
 * FullscreenModalTrigger
 *
 * An uncontrolled component that renders a button which opens a fullscreen modal dialog when clicked.
 * This component is built on top of React Aria's DialogTrigger, which handles the open/close state internally.
 *
 * @example
 * <FullscreenModalTrigger
 *   buttonText="Open Settings"
 *   buttonSize="medium"
 *   buttonVariant="primary"
 * >
 *   <dix>Content</div>
 * </FullscreenModalTrigger>
 */
export function FullscreenModalTrigger({
  buttonText,
  children,
  buttonSize = 'small',
  buttonVariant = 'primary',
  onTriggerClick,
  ...props
}: FullscreenModalTriggerProps): ReactElement {
  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <div> component with 'ember-testing' id)
  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;

  return (
    <DialogTrigger>
      <Button
        data-testid="full-screen-modal-trigger"
        onPress={onTriggerClick}
        size={buttonSize}
        variant={buttonVariant}
      >
        {buttonText}
      </Button>
      <ModalOverlay
        UNSTABLE_portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
        className={styles.overlay}
      >
        <Modal className={styles['full-screen-modal']} {...props}>
          <Dialog className={styles.dialog} data-testid="full-screen-modal-dialog">
            <FullscreenBase>{children}</FullscreenBase>
          </Dialog>
        </Modal>
      </ModalOverlay>
    </DialogTrigger>
  );
}
