import React, { type ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import styles from './footer-cta.strict-module.css';

interface FooterCTAProps {
  isLoading: boolean;
  isLastStep: boolean;
  onApprove: () => Promise<void>;
  onDecline: () => Promise<void>;
}

export function FooterCTA(props: FooterCTAProps): ReactNode {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.wrapper} data-test-reimbursements-bulk-sidebar-cta>
      <Button
        className={styles.success}
        data-test-reimbursements-bulk-sidebar-cta-confirm
        isLoading={props.isLoading}
        onPress={props.onApprove}
        size="small"
        variant="primary"
      >
        {props.isLastStep ? formatMessage({ id: 'btn.pay' }) : formatMessage({ id: 'btn.approve' })}
      </Button>
      <Button
        data-test-reimbursements-bulk-sidebar-cta-decline
        isLoading={props.isLoading}
        onPress={props.onDecline}
        size="small"
        variant="danger"
      >
        {formatMessage({ id: 'btn.decline' })}
      </Button>
    </div>
  );
}
