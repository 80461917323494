import React, { type ReactNode } from 'react';
import { Button, CloseIcon } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import styles from './sidebar-header.strict-module.css';

interface SidebarHeaderProps {
  onCloseSidebar: () => void;
  reimbursementsCount: number;
}

export function SidebarHeader(props: SidebarHeaderProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <header className={styles.container} data-test-reimbursements-bulk-sidebar-header>
      <p className={styles.title}>
        {formatMessage(
          { id: 'requests.reimbursements.sidepanel.bulk.selected' },
          { count: props.reimbursementsCount }
        )}
      </p>
      <Button
        aria-label={formatMessage({ id: 'a11y.buttons.close-aria-label' })}
        className={styles.closeButton}
        data-test-reimbursements-bulk-sidebar-action-close
        iconOnly
        onPress={props.onCloseSidebar}
        variant="tertiary"
      >
        <CloseIcon aria-hidden />
      </Button>
    </header>
  );
}
