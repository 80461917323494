import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useState } from 'react';
import type ProductModel from 'qonto/models/product.ts';
import { ignoreCancelation } from 'qonto/utils/ignore-error.ts';

interface FetchProductsArgs {
  query?: string;
  sortBy?: string;
}

interface UseProductsCatalogReturn {
  products: ProductModel[];
  fetchProducts: (options?: FetchProductsArgs) => void;
  isLoading: boolean;
}

export function useProductsCatalog(): UseProductsCatalogReturn {
  const productsCatalog = useEmberService('productsCatalog');
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  function fetchProducts({
    query,
    sortBy = 'title:asc,unit_price.value',
  }: FetchProductsArgs = {}): void {
    setIsLoading(true);
    productsCatalog.fetchProductsTask
      .perform({ sort_by: sortBy, query })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- ts doesn't like ember
        setProducts(productsCatalog.fetchProductsTask.lastSuccessful?.value ?? []);
      })
      .catch(ignoreCancelation)
      .finally(() => {
        setIsLoading(false);
      });
  }

  return {
    products,
    fetchProducts,
    isLoading,
  };
}
