import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { PaymentMethodSelectorToggle } from '@repo/domain-kit/invoices';
import { PaymentMethods as PaymentLinkMethod } from '@repo/domain-kit/shared';
import type Segment from 'qonto/services/segment';
import type { PaymentMethodsProps } from '../payment-methods';
import styles from '../styles.strict-module.css';

type PaymentMethodsSelectionProps = Pick<
  PaymentMethodsProps,
  'enabledPaymentMethods' | 'onPaymentMethodToggle' | 'selectedPaymentMethods'
>;

export function PaymentMethodsSelection({
  enabledPaymentMethods,
  onPaymentMethodToggle,
  selectedPaymentMethods,
}: PaymentMethodsSelectionProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment') as unknown as Segment;

  const isBankTransfer = (method: PaymentLinkMethod): boolean =>
    method === PaymentLinkMethod.BankTransfer;
  const isPaymentMethodSelected = (method: PaymentLinkMethod): boolean =>
    selectedPaymentMethods.includes(method);
  const handleFaqClick = (): void => {
    segment.track('payment-link_faq_clicked');
  };

  return (
    <>
      <p className="body-2" data-test-methods-description>
        <FormattedMessage
          id="receivable-invoices.payment-link-creation.body"
          values={{
            faqUrl: (
              <a
                className="body-link"
                data-test-link
                href={formatMessage({
                  id: 'receivable-invoices.payment-link-creation.inactive.url',
                })}
                onClick={handleFaqClick}
                rel="noopener noreferrer"
                target="_blank"
              >
                {formatMessage({
                  id: 'receivable-invoices.payment-link-creation.inactive.link',
                })}
              </a>
            ),
          }}
        />
      </p>

      <ul className={styles['payment-methods-list']}>
        {enabledPaymentMethods.map(({ method }) => (
          <li key={method}>
            <PaymentMethodSelectorToggle
              className={styles['payment-method']}
              isReadOnly={isBankTransfer(method)}
              isSelected={isPaymentMethodSelected(method)}
              method={method}
              onChange={() => {
                onPaymentMethodToggle(method);
              }}
              tooltipMessage={
                isBankTransfer(method)
                  ? formatMessage({
                      id: 'receivable-invoices.payment-link-creation.bank-transfer-tooltip',
                    })
                  : undefined
              }
            />
          </li>
        ))}
      </ul>
    </>
  );
}
