import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { SummaryItem } from './summary-item';
import type { SummaryItemProps } from './summary-item/summary-item';
import styles from './sidebar-summary.strict-module.css';

interface SummaryProps {
  itemsList: SummaryItemProps[];
}

export function SidebarSummary(props: SummaryProps): ReactNode {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.wrapper} data-test-reimbursements-bulk-sidebar-summary>
      <p className={styles.title} data-test-reimbursements-bulk-sidebar-summary-title>
        {formatMessage({ id: 'requests.reimbursements.sidepanel.bulk.summary' })}
      </p>
      <ul className={styles.list}>
        {props.itemsList.map((item: SummaryItemProps, i) => (
          <SummaryItem index={i} item={item} key={`summary-item-${item.name}`} />
        ))}
      </ul>
    </div>
  );
}
