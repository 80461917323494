import React, { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Avatar } from '@repo/design-system-kit';
import { formatMoney } from 'qonto/react/shared/utils/format-money';
import styles from './summary-item.strict-module.css';

interface AvatarProps {
  fileUrl?: string;
}

export interface SummaryItemProps {
  name: string;
  reimbursementsAmount: number;
  reimbursementsCount: number;
  avatar?: AvatarProps;
}

interface SummaryItemComponentProps {
  index: number;
  item: SummaryItemProps;
}

export function SummaryItem(props: SummaryItemComponentProps): ReactNode {
  const { formatMessage, locale } = useIntl();
  return (
    <li
      className={styles.wrapper}
      data-test-reimbursements-bulk-sidebar-summary-wrapper={props.index}
    >
      <div className={styles.content}>
        <Avatar
          alt={`${props.item.name} avatar`}
          className={styles.avatar}
          data-test-reimbursements-bulk-sidebar-summary-item-image={props.index}
          name={!props.item.avatar?.fileUrl ? props.item.name : ''}
          size="32"
          src={props.item.avatar?.fileUrl}
        />
        <div>
          <p data-test-reimbursements-bulk-sidebar-summary-item-name={props.index}>
            {props.item.name}
          </p>
          <p
            className={styles.count}
            data-test-reimbursements-bulk-sidebar-summary-item-count={props.index}
          >
            {formatMessage(
              { id: 'requests.reimbursements.sidepanel.bulk.reimbursements.count' },
              { count: props.item.reimbursementsCount }
            )}
          </p>
        </div>
      </div>
      <div data-test-reimbursements-bulk-sidebar-summary-item-total-amount={props.index}>
        {formatMoney(props.item.reimbursementsAmount, {
          currency: 'EUR',
          locale,
        })}
      </div>
    </li>
  );
}
