import { useMutation, type UseMutateFunction } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { transactionNamespace } from 'qonto/constants/hosts';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';
import type { TransactionModel } from 'qonto/react/models/transaction';
import { useRefetchTransaction } from './use-refetch-transaction';

export const useUnlinkTransactionAttachment = (
  transactionId: string
): {
  mutate: UseMutateFunction<TransactionModel, Error, string>;
  isPending: boolean;
} => {
  const fetchApi = useFetchApi();
  const store = useEmberService('store');
  const { mutate: refetchTransaction } = useRefetchTransaction(transactionId);

  const unlinkTransactionAttachment = async (attachmentId: string): Promise<TransactionModel> => {
    try {
      const response = await fetchApi(
        `${transactionNamespace}/transactions/${transactionId}/unlink_attachments`,
        {
          method: 'PATCH',
          body: JSON.stringify({
            transaction: {
              attachment_ids: [attachmentId],
            },
          }),
        }
      );

      const transaction = (await response.json()) as {
        transaction: TransactionModel;
      };

      store.pushPayload(transaction);

      return transaction.transaction;
    } catch (e) {
      throw new Error('Could not unlink attachment');
    }
  };

  const { mutate, isPending } = useMutation({
    mutationFn: unlinkTransactionAttachment,
    onSuccess: () => {
      refetchTransaction();
    },
  });

  return { mutate, isPending };
};
