import { useState, type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import { TextField } from '@repo/design-system-kit';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type ReceivableInvoiceModel from 'qonto/models/receivable-invoice';
import { formatBIC, formatIban } from 'qonto/utils/format-input';

interface ManualPaymentDetailsProps {
  className?: string;
  ibanError?: { message?: string; attribute?: string };
  invoice: ReceivableInvoiceModel;
}

export function ManualPaymentDetails({
  className,
  ibanError,
  invoice,
}: ManualPaymentDetailsProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useSegment();
  const { featureBooleanArAdvancedCustomization } = useFlags();
  const { organization } = useOrganizationManager();

  const countryCode = organization.legalCountry || 'FR';

  const ibanErrorMessage =
    ibanError?.message === 'required' || !String(invoice.iban).length
      ? formatMessage({
          id: 'receivable-invoices.invoice-creation.errors.iban-required-field',
        })
      : formatMessage({
          id: 'receivable-invoices.invoice-creation.errors.iban-invalid',
        });

  // in the invoice form the IBAN will never be obfuscated
  const isIBANObfuscated = false;

  // formatting the IBAN for display purpuses with spaces
  const [formattedIBAN, setFormattedIBAN] = useState<string>(
    formatIban(String(invoice.iban || ''), isIBANObfuscated)
  );

  const handleIBANChange = (value: string): void => {
    // BE will received the iban uppercase without spaces and/or non-alphanumeric characters
    const sanitizedValue = formatBIC(value);

    // this is also used to update the value in invoice pdf preview
    invoice.iban = sanitizedValue;

    setFormattedIBAN(formatIban(String(invoice.iban || ''), isIBANObfuscated));
  };

  const onIBANFocusOut = (): void => {
    segment.track('invoice_manual_iban_added');
  };

  const [formattedBIC, setFormattedBIC] = useState<string>(formatBIC(String(invoice.bic || '')));

  const handleBICChange = (value: string): void => {
    const sanitizedValue = formatBIC(value);

    invoice.bic = sanitizedValue;

    setFormattedBIC(String(invoice.bic || ''));
  };

  return (
    <div className={cx(className)}>
      <h2
        className={cx(featureBooleanArAdvancedCustomization ? 'title-3' : 'title-2', 'mb-16')}
        data-test-invoicing-form-payment-details-title
      >
        {formatMessage({ id: 'receivable-invoices.invoice-creation.payment-details.title' })}
      </h2>

      <TextField
        className="mb-16"
        data-test-invoicing-form-payment-details-iban
        errorMessage={ibanError ? ibanErrorMessage : ''}
        isInvalid={Boolean(ibanError)}
        label={formatMessage({
          id: 'receivable-invoices.invoice-creation.payment-details.input-label-iban',
        })}
        onBlur={onIBANFocusOut}
        onChange={handleIBANChange}
        placeholder={formatMessage(
          {
            id: 'placeholders.iban',
          },
          { legalCountry: countryCode }
        )}
        value={formattedIBAN}
      />

      <TextField
        className="mb-16"
        data-test-invoicing-form-payment-details-bic
        isOptional
        label={formatMessage({
          id: 'receivable-invoices.invoice-creation.payment-details.input-label-bic',
        })}
        maxLength={11}
        onChange={handleBICChange}
        placeholder={formatMessage({
          id: 'placeholders.bic_number',
        })}
        value={formattedBIC}
      />
    </div>
  );
}
