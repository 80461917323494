import { useEffect } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import type { InfiniteData, InfiniteQueryObserverResult } from '@tanstack/react-query';
import type { RefCallback } from 'react';

export const useInfiniteScroll = <TData>(
  isLoading: boolean,
  isFetchingNextPage: boolean,
  hasNextPage: boolean,
  fetchNextPage: () => Promise<InfiniteQueryObserverResult<InfiniteData<TData>>>
): RefCallback<Element> => {
  const { isIntersecting: isInView, ref: loadMoreRef } = useIntersectionObserver();

  useEffect(() => {
    if (isInView && hasNextPage && !(isLoading || isFetchingNextPage)) {
      void fetchNextPage();
    }
  }, [isInView, hasNextPage, isLoading, fetchNextPage, isFetchingNextPage]);

  return loadMoreRef;
};
