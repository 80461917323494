import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { CopyToClipboard } from 'qonto/react/shared/components/copy-to-clipboard';
import styles from './styles.strict-module.css';

interface ShareProps {
  shareableLink: string;
}

export function Share({ shareableLink }: ShareProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      <dt className={cx(styles.label, 'body-2')}>
        {formatMessage({ id: 'payment-links.sidebar.payment-link' })}
      </dt>
      <dd className={styles.value}>
        <a
          className={styles.link}
          data-test-payment-links-sidebar-share-link
          data-testid="payment-links-sidebar-share-link"
          href={shareableLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          {shareableLink}
        </a>
        <CopyToClipboard
          aria-label={formatMessage({ id: 'payment-links.sidebar.copy-payment-link.aria-label' })}
          className={styles['copy-btn']}
          clipboardText={shareableLink}
          data-test-payment-links-sidebar-share-copy
          toastMessage={formatMessage({ id: 'payment-links.sidebar.copy-payment-link.toast' })}
          trackEvent="payment-link_link_copied"
          trackParams={{ origin: 'payment_link_details' }}
        />
      </dd>
    </div>
  );
}
