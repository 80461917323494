import { type ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import { IconChevronRightOutlined } from '@repo/monochrome-icons';
import styles from './styles.strict-module.css';

export function CompanyCardLoading(): ReactNode {
  return (
    <div className={styles['company-card']} data-testid="company-card-loading">
      <div className={styles['text-container']}>
        <SkeletonLoader.Line className={styles['loader-line']} width="91px" />
        <SkeletonLoader.Line className={styles['loader-line']} width="156px" />
      </div>
      <div className={styles['chevron-icon']}>
        <IconChevronRightOutlined />
      </div>
    </div>
  );
}
