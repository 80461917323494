import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useMutation, useQueryClient, type UseMutateFunction } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { bulkTransactionsManager } from '../contexts/bulk-transactions-context';
import { bulkSelectionManager } from '../contexts/bulk-selection-context';
import { manageActiveTransactionsQueryCache } from '../utils/react-query-helpers';
import { ATTACHMENT_STATUS } from '../models/transaction';
import type { Transaction } from '../graphql';

export const useUpdateBulkActions = (): {
  mutate: UseMutateFunction<
    { success: boolean; fields: string[] }[],
    unknown,
    void,
    { previousTransactions: Transaction[] }
  >;
  isPending: boolean;
} => {
  const {
    labels: { aggregatedUpdatedLabels, newLabels, mutationFn: mutateBulkUpdates },
    verificationStatus: {
      verificationStatus,
      verificationStatusChanged,
      mutationFn: mutateBulkVerificationStatus,
    },
    category: { category, categoryChanged },
    attachmentStatus: {
      attachmentStatusChanged,
      attachmentStatus,
      mutationFn: mutateBulkAttachmentStatus,
    },
    requestAttachment: { requestAttachment: attachmentRequestedChanged },
    cashflowCategory: {
      cashflowCategoryChanged,
      cashflowCategory,
      mutationFn: mutateBulkCashflowCategory,
    },
  } = bulkTransactionsManager.useBulkTransactions();
  const { selection: selectedTransactions } = bulkSelectionManager.useBulkSelection();
  const queryClient = useQueryClient();
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const { formatMessage } = useIntl();
  const hasUpdatedLabels = aggregatedUpdatedLabels.length > 0;

  const submitBulkActionsFn = async (): Promise<{ success: boolean; fields: string[] }[]> => {
    const changedFieldsMutationFns = [];

    if (hasUpdatedLabels || categoryChanged || attachmentRequestedChanged) {
      const fields = [];
      if (hasUpdatedLabels) fields.push('labels');
      if (categoryChanged) fields.push('category');
      if (attachmentRequestedChanged) fields.push('requestAttachment');
      changedFieldsMutationFns.push({ fn: mutateBulkUpdates, fields });
    }

    if (cashflowCategoryChanged) {
      changedFieldsMutationFns.push({
        fn: mutateBulkCashflowCategory,
        fields: ['cashflowCategory'],
      });
    }

    if (verificationStatusChanged) {
      changedFieldsMutationFns.push({
        fn: mutateBulkVerificationStatus,
        fields: ['verificationStatus'],
      });
    }

    if (attachmentStatusChanged) {
      changedFieldsMutationFns.push({
        fn: mutateBulkAttachmentStatus,
        fields: ['attachmentStatus'],
      });
    }

    const results = await Promise.all(
      changedFieldsMutationFns.map(async ({ fn, fields }) => {
        try {
          await fn(selectedTransactions);
          return { success: true, fields };
        } catch (error) {
          return { success: false, fields };
        }
      })
    );

    return results;
  };

  const { mutate, isPending } = useMutation({
    mutationFn: submitBulkActionsFn,
    onMutate: () => {
      if (!selectedTransactions[0]) {
        throw new Error('No transaction selected');
      }

      const { bulkUpdateCache, getTransactionsFromIds } = manageActiveTransactionsQueryCache(
        selectedTransactions[0],
        queryClient
      );

      const transactions = getTransactionsFromIds(selectedTransactions);
      const previousTransactions = transactions.map(transaction => ({ ...transaction }));

      const updatedTransactions = transactions.map(transaction => {
        const updatedTransaction = { ...transaction };

        if (verificationStatusChanged) {
          updatedTransaction.qualifiedForAccounting = Boolean(verificationStatus);
        }

        if (categoryChanged) {
          updatedTransaction.activityTag = category;
        }

        if (cashflowCategoryChanged) {
          updatedTransaction.categoryAssignment = cashflowCategory
            ? {
                id: cashflowCategory,
                source: 'manual',
              }
            : null;
        }

        if (attachmentStatusChanged) {
          const attachmentLost = attachmentStatus === ATTACHMENT_STATUS.LOST;
          const attachmentRequired =
            attachmentStatus === ATTACHMENT_STATUS.REQUIRED || attachmentLost;
          updatedTransaction.attachmentLost = attachmentLost;
          updatedTransaction.attachmentRequired = attachmentRequired;
        }

        if (attachmentRequestedChanged) {
          updatedTransaction.attachmentRequestedAt = new Date().toISOString();
        }

        if (hasUpdatedLabels) {
          const mergedOldLabelsWithNewLabels = [
            ...updatedTransaction.labels.filter(
              label => !newLabels.some(newLabel => newLabel.listId === label.listId)
            ),
            ...newLabels,
          ];
          updatedTransaction.labels = mergedOldLabelsWithNewLabels;
        }

        return updatedTransaction;
      });

      bulkUpdateCache(updatedTransactions);

      return { previousTransactions };
    },
    onSettled: (data, _, __, context) => {
      if (!selectedTransactions[0] || !data || !context) {
        return;
      }

      const { bulkUpdateCache } = manageActiveTransactionsQueryCache(
        selectedTransactions[0],
        queryClient
      );

      const failedFields = data.filter(result => !result.success).flatMap(result => result.fields);
      if (failedFields.length === 0) {
        return;
      }

      const transactionsToRollback: Transaction[] = [];

      context.previousTransactions.forEach(oldTransaction => {
        const updatedTransaction = { ...oldTransaction };

        failedFields.forEach(field => {
          switch (field) {
            case 'category':
              updatedTransaction.activityTag = oldTransaction.activityTag;
              break;
            case 'cashflowCategory':
              updatedTransaction.categoryAssignment = oldTransaction.categoryAssignment;
              break;
            case 'verificationStatus':
              updatedTransaction.qualifiedForAccounting = oldTransaction.qualifiedForAccounting;
              break;
            case 'attachmentStatus':
              updatedTransaction.attachmentLost = oldTransaction.attachmentLost;
              updatedTransaction.attachmentRequired = oldTransaction.attachmentRequired;
              break;
            case 'labels':
              updatedTransaction.labels = oldTransaction.labels;
              break;
            case 'requestAttachment':
              updatedTransaction.attachmentRequestedAt = oldTransaction.attachmentRequestedAt;
              break;
          }
        });

        transactionsToRollback.push(updatedTransaction);
      });

      bulkUpdateCache(transactionsToRollback);

      toastFlashMessages.toastError(formatMessage({ id: 'toasts.errors.server_error' }));
    },
    onSuccess: data => {
      const numberOfTransactions = selectedTransactions.length;
      if (data.some(result => !result.success)) {
        return;
      }
      toastFlashMessages.toastInfo(
        formatMessage(
          { id: 'toasts.transactions_bulk_edit' },
          { count: numberOfTransactions.toString() }
        )
      );
    },
  });

  return {
    mutate,
    isPending,
  };
};
