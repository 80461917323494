import type { Amount } from 'qonto/react/models/amount';
import type { CashflowCategories } from 'qonto/react/models/cash-flow-category';
import type { CashflowAlert } from '../api/models/cash-flow-timeseries';
import type { CashflowForecastEntry, CashflowSideForecastEntry } from './cash-flow-forecast-entry';
import type { CashflowPeriodRate } from './cash-flow-period';

export interface TimeframeInterval {
  inclusiveStartDate: string;
  exclusiveEndDate: string;
}

export interface CashflowSubcategoryDataPoint {
  subcategoryId: string;
  transactionsCount: number;
  amountSum: Amount<string>;
  forecast?: CashflowForecastEntry;
  alerts?: CashflowAlert[];
}

export interface CashflowCategoryDataPoint {
  categoryId: string;
  subcategoriesDataPoints?: CashflowSubcategoryDataPoint[];
  transactionsCount: number;
  amountSum: Amount<string>;
  forecast?: CashflowForecastEntry;
  alerts?: CashflowAlert[];
}

export interface CashflowSideDatapoint {
  amountSum: Amount<string>;
  growthPercentage?: number;
  transactionsCount: number;
  categoriesDataPoints: CashflowCategoryDataPoint[];
  forecast?: CashflowSideForecastEntry;
  projectedAmount?: Amount<string>;
  alerts?: CashflowAlert[];
}

export interface CashflowVAT {
  creditAmount: Amount<string>;
  debitAmount: Amount<string>;
}

export interface CashflowTimeframeDatapoint {
  transactionsCount: number;
  startingAmount?: Amount<string>;
  endingAmount?: Amount<string>;
  forecastedStartingAmount?: Amount<string>;
  forecastedEndingAmount?: Amount<string>;
  projectedStartingAmount?: Amount<string>;
  projectedEndingAmount?: Amount<string>;
  inflows: CashflowSideDatapoint;
  outflows: CashflowSideDatapoint;
  vat?: CashflowVAT;
}

export interface CashflowTimeframe {
  cashFlowData: CashflowTimeframeDatapoint;
  inclusiveStartDate: string;
  exclusiveEndDate: string;
  requestedAt?: string; // ISO UTC date
  aggregationInterval?: CashflowPeriodRate;
}

export enum CashBurnComputationType {
  Default = 'default',
  Fallback = 'fallback',
  NoData = 'no_data',
}

export interface CashBurnData {
  amount: Amount<string>;
  computationType: CashBurnComputationType;
}

export type CurrentBalanceAmount = Amount<string>;

export interface VatData {
  creditAmount: Amount<string>;
  debitAmount: Amount<string>;
  netAmount: Amount<string>;
}

export interface CashflowTimeseries {
  timeframe: TimeframeInterval;
  categoriesData: CashflowCategories;
  timeframes: CashflowTimeframe[];
  cashBurnData: CashBurnData;
  currentBalanceAmount: CurrentBalanceAmount;
  vatData: VatData;
}

export type CashflowSide = 'inflows' | 'outflows';

export const DEFAULT_TIMESERIES = {
  timeframe: { inclusiveStartDate: '', exclusiveEndDate: '' },
  categoriesData: { inflow: [], outflow: [] },
  timeframes: [],
  cashBurnData: {
    amount: { currency: '', value: '' },
    computationType: CashBurnComputationType.Default,
  },
  currentBalanceAmount: { currency: '', value: '' },
  vatData: {
    creditAmount: { currency: '', value: '' },
    debitAmount: { currency: '', value: '' },
    netAmount: { currency: '', value: '' },
  },
};

export enum CashFlowAmountTypes {
  Actual = 'actual',
  Forecast = 'forecast',
  Projected = 'projected',
  GapToForecast = 'gapToForecast',
}
