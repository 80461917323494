import type { PropsWithChildren, ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { IconCopyOutlined } from '@repo/monochrome-icons';
import { CopyButton } from 'qonto/react/shared/components/copy-button';
import styles from './copy-to-clipboard.strict-module.css';

interface CopyToClipboardProps extends PropsWithChildren {
  className?: string;
  clipboardText: string;
  containerId?: string;
  toastMessage: string;
  trackEvent?: string;
  trackParams?: object;
}

export function CopyToClipboard({
  className,
  children,
  clipboardText,
  toastMessage,
  trackEvent,
  trackParams,
  ...props
}: CopyToClipboardProps): ReactNode {
  const segment = useEmberService('segment');
  const toastFlashMessage = useEmberService('toastFlashMessages');

  const onCopySuccess = (): void => {
    toastFlashMessage.toastInfo(toastMessage);
    if (trackEvent) {
      segment.track(trackEvent, trackParams);
    }
  };

  return (
    <CopyButton
      className={className}
      clipboardText={clipboardText}
      data-test-copy-to-clipboard=""
      onSuccess={onCopySuccess}
      {...props}
    >
      <span className={styles.content}>{children}</span>
      <IconCopyOutlined className={styles.icon} />
    </CopyButton>
  );
}
