/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import React, { type ReactNode } from 'react';
import { Badge, Button, Popup } from '@repo/design-system-kit';
import { FormattedMessage } from '@repo/domain-kit/shared';
import { useSegment } from '@repo/poly-hooks/segment';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import ENV from 'qonto/config/environment';
import { AttachmentIllustration } from './attachment-illustration';
import { ContentIllustration } from './content-illustration';
import { SectionIllustration } from './section-illustration';
import styles from './ar-features-promo-popup.strict-module.css';

export const FEATURES = {
  ATTACHMENTS: 'attachments',
  CUSTOMIZATION: 'customization',
  SECTIONS: 'sections',
} as const;

export const FEATURES_EVENTS = {
  [FEATURES.ATTACHMENTS]: 'attachments',
  [FEATURES.CUSTOMIZATION]: 'content_customization',
  [FEATURES.SECTIONS]: 'sections',
};

export type Feature = (typeof FEATURES)[keyof typeof FEATURES];

interface ArFeaturesPromoPopupProps {
  isOpen: boolean;
  onCancel: () => void;
  onCtaClick: () => void;
  hasFreeTrial?: boolean;
  feature: Feature;
}

type Copies = Record<string, { title: string; body: string; illustration: ReactNode }>;

const isTest = ENV.environment === 'test';

export function ArFeaturesPromoPopup({
  isOpen,
  onCancel,
  onCtaClick,
  hasFreeTrial = false,
  feature,
}: ArFeaturesPromoPopupProps): ReactNode {
  const { formatMessage } = useIntl();
  const { locale } = useEmberService('localeManager');
  const segment = useSegment();

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER =
    document.getElementById('ember-testing') ?? document.querySelector('main') ?? undefined;

  const copies: Copies = {
    [FEATURES.ATTACHMENTS]: {
      title: formatMessage({
        id: 'receivable-invoices.promotions.promotional-popup.attachments.title',
      }),
      body: formatMessage({
        id: 'receivable-invoices.promotions.promotional-popup.attachments.body',
      }),
      illustration: <AttachmentIllustration isFreeTrial={hasFreeTrial} locale={locale} />,
    },
    [FEATURES.CUSTOMIZATION]: {
      title: formatMessage({
        id: 'receivable-invoices.promotions.promotional-popup.content-customization.title',
      }),
      body: formatMessage({
        id: 'receivable-invoices.promotions.promotional-popup.content-customization.body',
      }),
      illustration: <ContentIllustration isFreeTrial={hasFreeTrial} locale={locale} />,
    },
    [FEATURES.SECTIONS]: {
      title: formatMessage({
        id: 'receivable-invoices.promotions.promotional-popup.sections.title',
      }),
      body: formatMessage({ id: 'receivable-invoices.promotions.promotional-popup.sections.body' }),
      illustration: <SectionIllustration isFreeTrial={hasFreeTrial} locale={locale} />,
    },
  };

  const handleClick = (): void => {
    segment.track('ar_add_on-feature_modal-clicked', {
      cta_type: hasFreeTrial ? 'free_trial' : 'upgrade',
      feature: FEATURES_EVENTS[feature],
    });
    onCtaClick();
  };

  return (
    <Popup.Root
      data-test-id="ar-features-promotional-popup"
      isOpen={isOpen}
      portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
    >
      <Popup.CloseButton onPress={onCancel} />
      <Popup.Illustration isPromotional>{copies[feature]?.illustration}</Popup.Illustration>
      <Popup.Badge>
        {hasFreeTrial ? (
          <Badge
            text={formatMessage({ id: 'promotion-system.badge.trial.label' })}
            type="tryForFree"
          />
        ) : (
          <Badge text={formatMessage({ id: 'upsell.add-ons.badge' })} type="upgrade" />
        )}
      </Popup.Badge>
      <Popup.Header>
        <Popup.Title>{copies[feature]?.title}</Popup.Title>
      </Popup.Header>
      <Popup.Body className={styles.popupBody}>
        <p className="body-1" data-test-id="modal-body">
          {`${copies[feature]?.body} `}
          <FormattedMessage id="receivable-invoices.promotions.promotional-popup.ar-addon.body" />
        </p>
      </Popup.Body>
      <Popup.Footer>
        <Button
          data-test-id="ar-advanced-customization-promo-popup-cta"
          onPress={handleClick}
          variant="primary"
        >
          {hasFreeTrial
            ? formatMessage({
                id: 'promotion-system.cta.trial',
              })
            : formatMessage({
                id: 'upsell.add-ons.cta',
              })}
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
