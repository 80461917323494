import { type ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { E_SIGNATURE_EVENT, E_SIGNATURE_STATUS } from 'qonto/react/receivable-invoices/constants';
import styles from './signature.strict-module.css';

interface SignatureProps {
  transitionToNext: () => void;
  context: {
    onCompleteRoute: string;
    quoteId: string;
    signature: {
      url: string;
      status: string;
    };
  };
}

interface SignatureEvent {
  origin: string;
  data?: {
    event: string;
    type: string;
  };
}

export function Signature({ transitionToNext, context }: SignatureProps): ReactElement {
  const { formatMessage } = useIntl();
  const { signature } = context;

  useEffect(() => {
    const handleEvents = (e: SignatureEvent): void => {
      let status;

      if (e.origin === E_SIGNATURE_EVENT.ORIGIN && e.data?.type === E_SIGNATURE_EVENT.TYPE) {
        if (e.data.event === 'success') {
          status = E_SIGNATURE_STATUS.SUCCESS;
        }

        if (e.data.event === 'error') {
          status = E_SIGNATURE_STATUS.ERROR;
        }
      }

      if (status) {
        context.signature.status = status;
        transitionToNext();
      }
    };

    window.addEventListener('message', handleEvents);
    return () => {
      window.removeEventListener('message', handleEvents);
    };
  }, [transitionToNext, context]);

  return (
    <div className={styles.signature}>
      <iframe
        className={styles.iframeContainer}
        data-test-signature
        referrerPolicy="strict-origin-when-cross-origin"
        src={`${signature.url}&disable_domain_validation=true`}
        title={formatMessage({ id: 'quotes.e-signature.iframe.title' })}
      />
    </div>
  );
}
