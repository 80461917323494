import { type ReactElement } from 'react';
import cx from 'clsx';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { FormattedMessage } from 'react-intl';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import { E_SIGNATURE_FLOW_REDIRECT } from 'qonto/react/receivable-invoices/constants';
import styles from './error.strict-module.css';

interface ErrorProps {
  restartFlow: () => void;
  context: {
    onCompleteRoute: string;
    quoteId: string;
    signature: {
      url: string;
      status: string;
    };
  };
}

export function Error({ restartFlow, context }: ErrorProps): ReactElement {
  context.onCompleteRoute = E_SIGNATURE_FLOW_REDIRECT.QUOTES_SHARE;

  const handlePrimaryCta = (): void => {
    restartFlow();
  };

  return (
    <div className={styles.container}>
      <LottiePlayer
        className={styles.lottie}
        data-test-error-lottie=""
        path={useThemedAssetPath('/lotties/generic_error.json')}
      />
      <h1 className="title-2 mb-16 align-center" data-test-error-title>
        <FormattedMessage id="receivable-invoices.quote-sending.e-signature.error.title" />
      </h1>
      <p className={cx(styles.body, 'body-1 mb-32 align-center')} data-test-error-body>
        <FormattedMessage id="receivable-invoices.quote-sending.e-signature.error.body" />
      </p>
      <Button className="mb-16" data-test-error-primary-cta onPress={handlePrimaryCta}>
        <FormattedMessage id="btn.retry" />
      </Button>
    </div>
  );
}
