import { useQuery } from '@tanstack/react-query';
import type { QueryObserverOptions, UseQueryResult } from '@tanstack/react-query';
import { transactionNamespace } from 'qonto/constants/hosts';
import type { Transaction, TransactionResponse } from 'qonto/react/models/transaction';
import { camelizeKeys } from '../api/utils/camelize-keys';
import { useFetchApi } from './use-fetch-api';

export function useFetchTransactionsByIds(
  transactionIds: string[],
  queryOptions: Partial<QueryObserverOptions<Transaction[]>>
): UseQueryResult<Transaction[]> {
  const fetchApi = useFetchApi();

  const fetchTransaction = async (transactionId: string): Promise<Transaction> => {
    const response = await fetchApi(`${transactionNamespace}/transactions/${transactionId}`);

    if (!response.ok) {
      throw new Error('Failed to fetch transaction');
    }

    const { transaction } = (await response.json()) as {
      transaction: TransactionResponse;
    };

    return camelizeKeys(transaction);
  };

  return useQuery({
    ...queryOptions,
    queryKey: ['transactions', transactionIds],
    queryFn: async () => Promise.all(transactionIds.map(id => fetchTransaction(id))),
  });
}
