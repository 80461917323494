import { type QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { cashFlowCategoriesNamespace } from 'qonto/constants/hosts';
import { type CashflowCategories, type CashflowParentCategory } from '../models/cash-flow-category';
import type { CashflowCategories as CashflowCategoriesApiResponse } from '../api/models/cash-flow-category';
import { camelizeKeys } from '../api/utils/camelize-keys';
import type { QueryResult } from '../api/utils/query-result';
import { useMapCategoriesWithData } from '../utils/cashflow-categories/categories-data-map';
import { useFetchApi } from './use-fetch-api';
import { useOrganizationManager } from './use-organization-manager';

export const QUERY_KEYS = {
  base: ['categories'] as const,
  all: (organizationId: string) => [...QUERY_KEYS.base, organizationId] as const,
  inflow: (organizationId: string) => [...QUERY_KEYS.base, organizationId, 'inflow'] as const,
  outflow: (organizationId: string) => [...QUERY_KEYS.base, organizationId, 'outflow'] as const,
  byId: (organizationId: string, categoryId: string) =>
    [...QUERY_KEYS.base, organizationId, categoryId] as const,
};

export function useFetchCashflowCategories(disabled = false): QueryResult<CashflowCategories> {
  const fetchApi = useFetchApi();
  const queryClient = useQueryClient();
  const { mapCategoriesWithData } = useMapCategoriesWithData();
  const { organization } = useOrganizationManager();
  const { id: organizationId } = organization;

  const fetchCashflowCategories = async (): Promise<CashflowCategories | undefined> => {
    const endpointUrl = `${cashFlowCategoriesNamespace}/cash-flow/categories?organization_id=${organizationId}`;
    const response = await fetchApi(endpointUrl);

    if (!response.ok) {
      throw new Error(`Error fetching categories for organization: ${organizationId}`);
    }

    const data = (await response.json()) as CashflowCategoriesApiResponse;
    const categories = camelizeKeys(data.categories) as CashflowCategories;
    const mappedCategories = mapCategoriesWithData(categories);
    cacheCategories({ organizationId, categories: mappedCategories, queryClient });

    return mappedCategories;
  };

  const {
    data,
    isLoading: isPending,
    isError,
    refetch,
  } = useQuery({
    queryKey: QUERY_KEYS.all(organizationId),
    queryFn: () => fetchCashflowCategories(),
    staleTime: 1000 * 60 * 30, // 30 min,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    enabled: !disabled,
  });

  return {
    data,
    isPending,
    isError,
    refetch,
  };
}

const cacheCategories = ({
  organizationId,
  categories,
  queryClient,
}: {
  organizationId: string;
  categories: CashflowCategories;
  queryClient: QueryClient;
}): void => {
  queryClient.setQueryData(QUERY_KEYS.inflow(organizationId), categories.inflow);
  queryClient.setQueryData(QUERY_KEYS.outflow(organizationId), categories.outflow);

  const cacheCategory = (category: CashflowParentCategory): void => {
    queryClient.setQueryData(QUERY_KEYS.byId(organizationId, category.id ?? ''), category);
    category.subcategories?.forEach(subcategory => {
      queryClient.setQueryData(QUERY_KEYS.byId(organizationId, subcategory.id ?? ''), subcategory);
    });
  };

  [...categories.inflow, ...categories.outflow].forEach(cacheCategory);
};
