import { useRef, type ReactNode } from 'react';
import { motion, AnimatePresence, type HTMLMotionProps } from 'framer-motion';
import cx from 'clsx';
import ENV from 'qonto/config/environment';
import { useClickOutside } from 'qonto/react/hooks/ui/use-click-outside';
import styles from './styles.strict-module.css';

interface SidePanelProps extends HTMLMotionProps<'aside'> {
  isVisible: boolean;
  children?: ReactNode;
  width?: number;
  onClickOutside?: () => void;
  options?: {
    ignore?: string[];
  };
}

const ANIMATION_DURATION_IN_SECONDS = 0.5;
const duration = ENV.environment === 'test' ? 0 : ANIMATION_DURATION_IN_SECONDS;

export function SidePanel({
  children,
  isVisible = false,
  width = 420,
  onClickOutside,
  options,
  className,
  ...props
}: SidePanelProps): ReactNode {
  const ref = useRef<HTMLDivElement>(null);
  const handleClickOutside = (): void => onClickOutside?.();
  useClickOutside(ref, handleClickOutside, {
    ignore: options?.ignore,
    delay: ANIMATION_DURATION_IN_SECONDS * 1000,
  });

  return (
    <AnimatePresence>
      {isVisible ? (
        <motion.aside
          animate={{ x: '0%' }}
          className={cx(styles['side-panel'], className)}
          data-testid="side-panel"
          exit={{ x: '100%' }}
          initial={{ x: '100%' }}
          ref={ref}
          style={{ width: `${width}px` }}
          transition={{ duration, ease: [0.55, 0, 0.1, 1] }}
          {...props}
        >
          {children}
        </motion.aside>
      ) : null}
    </AnimatePresence>
  );
}
