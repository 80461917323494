import { type MouseEventHandler, type PropsWithChildren, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface LayoutProps extends PropsWithChildren {
  title?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export function Layout({ children, title, onClick, ...props }: LayoutProps): ReactNode {
  return (
    <div data-test-claim-layout {...props}>
      {Boolean(title) && (
        <h3 className="title-4 mb-16" data-test-claim-layout-title>
          {title}
        </h3>
      )}
      {Boolean(children) && (
        <p className="body-2 mb-32" data-test-claim-layout-description>
          {children}
        </p>
      )}
      {Boolean(onClick) && (
        <button
          className="btn btn--primary btn--stretch"
          data-test-claim-layout-cta
          onClick={onClick}
          type="button"
        >
          <FormattedMessage id="transactions.sidebar.helper.dispute.cta" />
        </button>
      )}
    </div>
  );
}
