import { useState } from 'react';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';
import { clientHubV2Namespace } from 'qonto/constants/hosts';
import type { CompaniesSearchParams, CompanyData, UseCompaniesSearchReturn } from '../types';

export function useCompaniesSearch(): UseCompaniesSearchReturn {
  const [isLoading, setIsLoading] = useState(false);
  const fetchApi = useFetchApi();

  const companiesSearch = async ({
    legalCountry,
    query,
    limit,
  }: CompaniesSearchParams): Promise<CompanyData[]> => {
    setIsLoading(true);

    const queryParams = new URLSearchParams({
      country_code: legalCountry,
      query,
      limit: limit.toString(),
    });

    try {
      const response = await fetchApi(
        `${clientHubV2Namespace}/clients/companies?${queryParams.toString()}`,
        {
          method: 'GET',
        }
      );

      if (!response.ok) {
        throw new Error(
          response.status === 422 ? 'Invalid parameters provided' : 'An unexpected error occurred'
        );
      }

      const data = (await response.json()) as { data: { attributes: CompanyData }[] };

      const formattedData = data.data.map(
        (company: { attributes: CompanyData }) => company.attributes
      );

      return formattedData;
    } catch (e) {
      throw new Error('An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return { companiesSearch, isLoading };
}
