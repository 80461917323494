import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import ENV from 'qonto/config/environment';
import { upcomingTransactionsNamespace } from 'qonto/constants/hosts.ts';
import { useFetchApi } from './use-fetch-api';

export interface UpcomingTransaction {
  id: string;
  counterparty_name: string;
  provider_object_type: string;
  provider_object_id: string;
  due_date: string;
  expected_date: string;
  side: 'credit' | 'debit';
  amount: {
    value: string;
    currency: string;
  };
  metadata?: {
    invoice_number: string;
    logo_url?: string;
    recurrence?: { frequency: string };
    supplier_invoice_id?: string;
  };
}

export interface StatsOverview {
  overdue: {
    currency: string;
    value: string;
  };
  scheduled: {
    currency: string;
    value: string;
  };
  total: {
    currency: string;
    value: string;
  };
}

export interface Statistics {
  current_balance: { currency: string; value: string };
  to_collect: StatsOverview;
  to_pay: StatsOverview;
}

export interface PredictedBalance {
  date: string;
  total_amount_for_the_day: {
    currency: string;
    value: string;
  };
  predicted_balance: {
    currency: string;
    value: string;
  };
}

export interface UpcomingTransactionsResponse {
  predicted_balances?: PredictedBalance[];
  upcoming_transactions?: UpcomingTransaction[];
  statistics: Statistics;
  meta?: {
    total_count: number;
    per_page: number;
    current_page: number;
  };
}

export interface UpcomingTransactionsResult {
  data: UpcomingTransactionsResponse | undefined;
  isPending: boolean;
  isError: boolean;
}

export const useUpcomingTransactions = (
  perPage: number,
  page: number,
  status: string,
  bankAccountIds?: string,
  selectedPeriod: '30' | '90' = '30'
): UpcomingTransactionsResult => {
  const fetchApi = useFetchApi();
  const startDate = dayjs();
  const endDate = startDate.add(parseInt(selectedPeriod, 10), 'days');

  const formattedStartDate = startDate.format('YYYY-MM-DD');
  const formattedEndDate = endDate.format('YYYY-MM-DD');
  const bankAccountIdsArray = bankAccountIds ? bankAccountIds.split(',') : undefined;

  return useQuery({
    queryKey: ['upcoming-transactions', page, perPage, bankAccountIds, selectedPeriod],
    queryFn: async () => {
      const response = await fetchApi(
        `${upcomingTransactionsNamespace}/cash-flow/upcoming-transactions/overview`,
        {
          method: 'POST',
          body: JSON.stringify({
            pagination: { per_page: perPage, page },
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            statuses: [status],
            ...(bankAccountIdsArray && { bank_account_ids: bankAccountIdsArray }),
          }),
        }
      );
      const data: UpcomingTransactionsResponse =
        (await response.json()) as UpcomingTransactionsResponse;
      return data;
    },
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    retry: ENV.environment === 'test' ? false : 3,
  });
};
