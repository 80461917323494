import { useEffect, type RefObject } from 'react';

export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (event: MouseEvent | TouchEvent) => void,
  options?: {
    ignore?: string[];
    delay?: number;
  }
): void => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent): void => {
      const target = event.target as HTMLElement;

      if (
        !ref.current ||
        ref.current.contains(event.target as Node) ||
        options?.ignore?.some(selector => target.closest(selector))
      ) {
        return;
      }

      handler(event);
    };

    const attachListener = (): void => {
      document.addEventListener('click', listener);
    };

    if (options?.delay) {
      const timeoutId = setTimeout(attachListener, options.delay);
      return () => {
        document.removeEventListener('click', listener);
        clearTimeout(timeoutId);
      };
    }

    attachListener();
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [ref, handler, options]);
};
