import React, { useEffect, useState, type ReactNode } from 'react';
import { Button, LottiePlayer, Popup } from '@repo/design-system-kit';
import { useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import ENV from 'qonto/config/environment';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { AR_ADVANCED_INSTRUCTIONAL_PROMO_KEY_STORAGE } from 'qonto/react/receivable-invoices/constants';
import styles from './ar-advanced-customization-promo-popup.strict-module.css';

const isTest = ENV.environment === 'test';

const popupDelay = isTest ? 0 : 2000;

interface ArAdvancedCustomizationPromoPopupProps {
  isQuote?: boolean;
}

export function ArAdvancedCustomizationPromoPopup({
  isQuote = false,
}: ArAdvancedCustomizationPromoPopupProps): ReactNode {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { organization } = useOrganizationManager();
  const router = useRouter();

  useEffect(() => {
    if (safeLocalStorage.getItem(AR_ADVANCED_INSTRUCTIONAL_PROMO_KEY_STORAGE) === 'true') {
      return;
    }

    const timeout = setTimeout(() => {
      setIsOpen(true);
      safeLocalStorage.setItem(AR_ADVANCED_INSTRUCTIONAL_PROMO_KEY_STORAGE, 'true');
    }, popupDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleCancel = (): void => {
    setIsOpen(false);
  };

  const handleConfirm = (): void => {
    if (isQuote) {
      void router.push(`/organizations/${organization.slug}/quotes/new`);
      return;
    }

    void router.push(`/organizations/${organization.slug}/receivable-invoices/new`);
  };

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER =
    document.getElementById('ember-testing') ?? document.querySelector('main') ?? undefined;

  return (
    <Popup.Root
      className={styles.popup}
      data-test-id="ar-advanced-customization-promotional-popup"
      isOpen={isOpen}
      portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
    >
      <Popup.CloseButton className={styles.closeButton} onPress={handleCancel} />
      <Popup.Illustration isPromotional>
        <LottiePlayer
          className={styles.lottie}
          path="/lotties/receivable-invoices/section-and-attachments.json"
        />
      </Popup.Illustration>
      <Popup.Header>
        <Popup.Title>
          {isQuote
            ? formatMessage({
                id: 'receivable-invoices.promotions.promotional-popup.ar-advanced.quotes.title',
              })
            : formatMessage({
                id: 'receivable-invoices.promotions.promotional-popup.ar-advanced.invoices.title',
              })}
        </Popup.Title>
      </Popup.Header>
      <Popup.Body className={styles.popupBody}>
        <p className="body-1">
          {formatMessage({
            id: 'receivable-invoices.promotions.promotional-popup.ar-advanced.body',
          })}
        </p>
      </Popup.Body>
      <Popup.Footer>
        <Button
          data-test-id="ar-advanced-customization-promo-popup-cta"
          onPress={handleConfirm}
          variant="primary"
        >
          {isQuote
            ? formatMessage({
                id: 'receivable-invoices.quotes-list.cta',
              })
            : formatMessage({
                id: 'receivable-invoices.invoices-list.cta',
              })}
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
