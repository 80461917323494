import { useEffect, useRef, useState } from 'react';
import type { Path } from 'qonto/react/types/path.ts';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- we need the "any" to avoid type conflicts
type FieldValues = Record<string, any>;

// Convert `foo.bar` to `fooBar`
type DotToCamel<S extends string> = S extends `${infer First}.${infer Second}${infer Rest}`
  ? `${First}${Uppercase<Second>}${DotToCamel<Rest>}`
  : S;

// Convert `fooBar` to `foo_bar`
type CamelToSnake<S extends string> = S extends `${infer First}${infer Rest}`
  ? First extends Lowercase<First>
    ? `${First}${CamelToSnake<Rest>}`
    : `_${Lowercase<First>}${CamelToSnake<Rest>}`
  : S;

// Return `foo.bar | fooBar` from `foo.bar`
// Needed as some errors are attributed to camelCase attributes when it's a subfield
// i.e. `discount.amount` field populates the `discountAmount` error field
type NormalizePath<S extends string> = S | DotToCamel<S> | CamelToSnake<S>;

export type FieldPath<TFieldValues extends FieldValues> = NormalizePath<Path<TFieldValues>>;

interface FormError<T extends FieldValues = FieldValues> {
  attribute: FieldPath<T>;
  message: string;
}

export type FormErrors<T extends FieldValues = FieldValues> = FormError<T>[];

interface FormState {
  isValid: boolean;
}

interface UseFormArgs<T extends FieldValues = FieldValues> {
  defaultValues?: Partial<T>;
  errors?: FormErrors<T>;
  onUpdateValue?: <K extends keyof T>(attribute: K, value: T[K]) => void;
  onClearErrors?: <K extends keyof T>(attribute: K[]) => void;
}

interface UseFormReturn<T extends FieldValues = FieldValues> {
  getValue: <K extends keyof T>(attribute: K) => T[K] | undefined;
  setValue: <K extends keyof T>(attribute: K, value: T[K]) => void;
  errorsOf: (attribute: FieldPath<T>) => FormError<T>[] | undefined;
  hasError: (attribute: FieldPath<T>) => boolean;
  getError: (attribute: FieldPath<T>) => FormError<T> | undefined;
  clearErrors: (attributes: FieldPath<T> | FieldPath<T>[]) => void;
  formState: FormState;
}

export function useForm<T extends FieldValues = FieldValues>(
  args?: UseFormArgs<T>
): UseFormReturn<T> {
  const _formControl = useRef<FormController<T>>(createFormControl(args));
  const [emberDataState, setEmberDataState] = useState<Partial<T>>(args?.defaultValues ?? {});
  const [formState, setFormState] = useState<FormState>({ isValid: true });

  const control = _formControl.current.control;

  useEffect(
    () =>
      control._subscribeToState(() => {
        setFormState({ ...control._formState });
      }),
    [control]
  );

  useEffect(() => {
    _formControl.current.setErrors(args?.errors);
  }, [args?.errors, args?.errors?.length]);

  function getValue<K extends keyof T>(attribute: K): T[K] | undefined {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- caused by the "any" type of FieldValues
    return emberDataState[attribute];
  }

  function setValue<K extends keyof T>(attribute: K, value: T[K]): void {
    setEmberDataState(prevState => ({
      ...prevState,
      [attribute]: value,
    }));
    args?.onUpdateValue?.(attribute, value);
  }

  function hasError(attribute: FieldPath<T>): boolean {
    return _formControl.current.hasError(attribute);
  }

  function getError(attribute: FieldPath<T>): FormError<T> | undefined {
    return _formControl.current.getError(attribute);
  }

  function errorsOf(attribute: FieldPath<T>): FormError<T>[] | undefined {
    return _formControl.current.errorsOf(attribute);
  }

  function clearErrors(path: FieldPath<T> | FieldPath<T>[]): void {
    const paths = Array.isArray(path) ? path : [path];
    _formControl.current.clearErrors(paths);
    args?.onClearErrors?.(paths);
  }

  return {
    getValue,
    setValue,
    errorsOf,
    hasError,
    getError,
    clearErrors,
    formState,
  };
}

type Listener = () => void;

interface FormController<T extends FieldValues = FieldValues> {
  setErrors: (errors?: FormErrors<T>) => void;
  clearErrors: (paths: FieldPath<T>[]) => void;
  hasError: (path: FieldPath<T>) => boolean;
  getError: (path: FieldPath<T>) => FormError<T> | undefined;
  errorsOf: (prefix: FieldPath<T>) => FormErrors<T> | undefined;
  control: {
    _subscribeToState: (listener: Listener) => () => void;
    _formState: FormState;
  };
}

function createFormControl<T extends FieldValues = FieldValues>(
  args?: UseFormArgs<T>
): FormController<T> {
  let _listeners: Listener[] = [];
  let errors: Partial<Record<FieldPath<T>, FormError<T>>> = _parseErrors(args?.errors);
  let _formState: FormState = {
    isValid: Object.keys(errors).length === 0,
  };

  function _updateFormState(): void {
    _formState = {
      isValid: Object.keys(errors).length === 0,
    };
  }

  function _emit(): void {
    for (const listener of _listeners) {
      listener();
    }
  }

  function _subscribeToState(listener: Listener): () => void {
    _listeners = [..._listeners, listener];

    return () => {
      _listeners = _listeners.filter(l => l !== listener);
    };
  }

  function _parseErrors(_errors?: FormErrors<T>): Partial<Record<FieldPath<T>, FormError<T>>> {
    return (_errors ?? []).reduce<Partial<Record<FieldPath<T>, FormError<T>>>>((_errs, err) => {
      _errs[err.attribute] = err;
      return _errs;
    }, {});
  }

  function setErrors(_errors?: FormErrors<T>): void {
    errors = _parseErrors(_errors);
    _updateFormState();
    _emit();
  }

  function clearErrors(paths: FieldPath<T>[]): void {
    paths.forEach(path => {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- :shrug:
      delete errors[path];
    });
    _updateFormState();
    _emit();
  }

  function hasError(path: FieldPath<T>): boolean {
    return Boolean(getError(path));
  }

  function getError(path: FieldPath<T>): FormError<T> | undefined {
    return errors[path];
  }

  function errorsOf(prefix: FieldPath<T>): FormErrors<T> | undefined {
    const regExp = new RegExp(`^${prefix}${prefix.endsWith('/') ? '' : '/'}`, 'g');
    return Object.entries<FormError<T>>(errors as Record<string, FormError<T>>).reduce<
      FormErrors<T>
    >((_errors, [attribute, error]) => {
      if (attribute.match(regExp)) {
        _errors.push({
          ...error,
          // @ts-expect-error -- we have to edit the attribute to remove the path requested
          attribute: error.attribute.replace(regExp, ''),
        });
      }
      return _errors;
    }, []);
  }

  return {
    control: {
      _subscribeToState,
      get _formState(): FormState {
        return _formState;
      },
    },
    setErrors,
    clearErrors,
    hasError,
    getError,
    errorsOf,
  };
}
