import { Fragment, type ReactNode } from 'react';
import cx from 'clsx';
import { IconArrowSmallRightFilled } from '@repo/monochrome-icons';
import styles from './styles.strict-module.css';

interface BreadcrumbsProps {
  trail: string[];
}

export function Breadcrumbs({ trail }: BreadcrumbsProps): ReactNode {
  const hasMultipleItems = trail.length > 1;

  return (
    <div
      className={cx(styles.breadcrumbs, hasMultipleItems && styles.multiple)}
      data-test-breadcrumbs=""
    >
      {trail.map((label, index) => (
        <Fragment key={label}>
          {index > 0 && <IconArrowSmallRightFilled aria-hidden="true" data-test-icon-arrow-right />}
          <span data-test-label={index}>{label}</span>
        </Fragment>
      ))}
    </div>
  );
}
