import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Disclaimer } from '@repo/design-system-kit';
import { TrackRender } from 'qonto/react/components/track-render';
import type { PaymentMethodsProps } from '../payment-methods';

function Link({ href, text }: { href: string; text: string }): ReactNode {
  return (
    <a className="body-link" data-test-link href={href} rel="noopener noreferrer" target="_blank">
      {text}
    </a>
  );
}

type PaymentMethodsDisclaimerProps = Pick<
  PaymentMethodsProps,
  | 'shouldShowDisabledDisclaimer'
  | 'shouldShowDisabledMethodsDisclaimer'
  | 'shouldShowPendingDisclaimer'
>;

export function PaymentMethodsDisclaimer({
  shouldShowDisabledDisclaimer,
  shouldShowDisabledMethodsDisclaimer,
  shouldShowPendingDisclaimer,
}: PaymentMethodsDisclaimerProps): ReactNode {
  const { formatMessage } = useIntl();

  const getDisclaimer = (): ReactNode => {
    if (shouldShowDisabledMethodsDisclaimer) {
      const url = formatMessage({ id: 'receivable-invoices.payment-link-creation.inactive.url' });
      const linkText = formatMessage({
        id: 'receivable-invoices.payment-link-creation.unavailable.link',
      });
      const messageTemplate = formatMessage(
        { id: 'receivable-invoices.payment-link-creation.unavailable.disclaimer' },
        {
          faqUrl: <Link href={url} key="disabled-methods" text={linkText} />,
        }
      );
      return messageTemplate;
    }

    if (shouldShowPendingDisclaimer) {
      const url = formatMessage({
        id: 'receivable-invoices.payment-link-creation.pending.disclaimer.url',
      });
      const linkText = formatMessage({
        id: 'receivable-invoices.payment-link-creation.pending.disclaimer.link',
      });
      const messageTemplate = formatMessage(
        { id: 'receivable-invoices.payment-link-creation.pending.disclaimer.text' },
        {
          mollieLink: <Link href={url} key="pending-connection" text={linkText} />,
        }
      );
      return messageTemplate;
    }

    if (shouldShowDisabledDisclaimer) {
      const url = formatMessage({
        id: 'receivable-invoices.payment-link-creation.disabled.disclaimer.url',
      });
      const linkText = formatMessage({
        id: 'receivable-invoices.payment-link-creation.disabled.disclaimer.link',
      });
      const messageTemplate = formatMessage(
        { id: 'receivable-invoices.payment-link-creation.disabled.disclaimer.text' },
        {
          mollieLink: <Link href={url} key="disabled-connection" text={linkText} />,
        }
      );
      return messageTemplate;
    }

    return null;
  };

  const disclaimer = getDisclaimer();

  if (!disclaimer) {
    return null;
  }

  const getTrackingParams = (): Record<string, string> | undefined => {
    if (shouldShowDisabledDisclaimer) {
      return { disclaimerType: 'disabled' };
    }
    if (shouldShowPendingDisclaimer) {
      return { disclaimerType: 'pending_verification' };
    }
    return undefined;
  };

  const trackingParams = getTrackingParams();
  const level = shouldShowDisabledMethodsDisclaimer ? 'info' : 'warning';

  return (
    <>
      <Disclaimer.Block level={level}>{disclaimer}</Disclaimer.Block>
      <TrackRender eventName="payment-link_disclaimer_shown" params={trackingParams} />
    </>
  );
}
