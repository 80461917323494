import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_SORT_ORDER } from 'qonto/constants/sort';
import { SortIcon } from 'qonto/react/components/paginated-table';
import styles from './pending.strict-module.css';

type SortField = 'request_type' | 'initiator' | 'created_at';
type SortOrder = 'asc' | 'desc';
type SortFull = `${SortField}:${SortOrder}`;

interface PendingProps {
  sortBy?: SortFull;
  handleSortBy?: (sort: SortFull) => void;
  compact?: boolean;
}

export function Pending({ sortBy, handleSortBy, compact }: PendingProps): ReactNode {
  const sortField = sortBy?.split(':')[0] as SortField;
  const sortOrder = sortBy?.split(':')[1] || DEFAULT_SORT_ORDER;

  const getAriaSort = (columnField: string): 'none' | 'ascending' | 'descending' => {
    if (columnField !== sortField) return 'none';
    return sortOrder === 'asc' ? 'ascending' : 'descending';
  };

  const handleSort = (name: SortField): void => {
    let order: 'asc' | 'desc' = DEFAULT_SORT_ORDER;

    if (name === sortField) {
      order = sortOrder === 'asc' ? 'desc' : 'asc';
    }

    handleSortBy?.(`${name}:${order}`);
  };

  return (
    <>
      <th
        aria-hidden="true"
        className={cx(styles['header-cell'], styles.empty)}
        data-test-task-table-header
        scope="col"
      />
      <th
        aria-sort={getAriaSort('request_type')}
        className={cx(styles['header-cell'], styles.type)}
        data-test-table-header-task-type
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'request_type' && styles.active)}
          onClick={() => {
            handleSort('request_type');
          }}
          type="button"
        >
          <FormattedMessage id="tasks.headers.type" />
          <SortIcon isActive={sortField === 'request_type'} isAscending={sortOrder === 'asc'} />
        </button>
      </th>
      <th
        aria-sort={getAriaSort('initiator')}
        className={cx(styles['header-cell'], styles.requester)}
        data-test-table-header-task-initiator
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'initiator' && styles.active)}
          onClick={() => {
            handleSort('initiator');
          }}
          type="button"
        >
          <FormattedMessage id="tasks.headers.from" />
          <SortIcon isActive={sortField === 'initiator'} isAscending={sortOrder === 'asc'} />
        </button>
      </th>
      <th
        aria-sort={getAriaSort('created_at')}
        className={cx(styles['header-cell'], styles['request-date'])}
        data-test-table-header-task-date
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'created_at' && styles.active)}
          onClick={() => {
            handleSort('created_at');
          }}
          type="button"
        >
          <FormattedMessage id="request.table.cell.date.created-on" />
          <SortIcon isActive={sortField === 'created_at'} isAscending={sortOrder === 'asc'} />
        </button>
      </th>
      <th
        className={cx(styles['header-cell'], styles.amount)}
        data-test-table-header-task-amount
        scope="col"
      >
        <div className={styles['header-content']}>
          <FormattedMessage id="tasks.headers.amount-limit" />
        </div>
      </th>
      <th
        className={cx(styles['header-cell'], styles.receipt)}
        data-test-table-header-task-receipt
        scope="col"
      >
        <div className={styles['header-content']}>
          <FormattedMessage id="tasks.headers.receipt" />
        </div>
      </th>
      {!compact ? (
        <th
          className={cx(styles['header-cell'], styles['quick-actions'])}
          data-test-table-header-task-quick-actions
          scope="col"
        />
      ) : null}
      <th aria-hidden="true" className={cx(styles['header-cell'], styles.empty)} scope="col" />
    </>
  );
}
