import { useMutation, type UseMutateFunction } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { cashFlowCategoriesNamespaceV2 } from 'qonto/constants/hosts';
import type { MemorizeCategoriesRule } from '../models/memorize-category';
import { useFetchApi } from './use-fetch-api';
import { useCategorizationRulesManager } from './use-categorization-rules-manager';

export const useCreateCategorizationRules = (): {
  mutate: UseMutateFunction<void, Error, MemorizeCategoriesRule[]>;
} => {
  const fetchApi = useFetchApi();
  const { formatMessage } = useIntl();
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const categorizationManager = useCategorizationRulesManager();

  const createCategorizationRules = async (rules: MemorizeCategoriesRule[]): Promise<void> => {
    const formattedRules = rules.map(rule => {
      return {
        category_id: rule.categoryId,
        matching_criteria: {
          raw_counterparty_name: rule.matchingCriteria.rawCounterpartyName,
          counterparty_name: rule.matchingCriteria.counterpartyName,
        },
        include_past: rule.includePast,
      };
    });

    try {
      const response = await fetchApi(
        `${cashFlowCategoriesNamespaceV2}/cash-flow/categorization-rules`,
        {
          method: 'POST',
          body: JSON.stringify({ data: formattedRules }),
        }
      );

      if (!response.ok) throw Error('Category rules could not be saved');
    } catch (e) {
      throw new Error('Category rules could not be saved');
    }
  };

  return useMutation({
    mutationFn: createCategorizationRules,
    onSuccess: (_, rules) => {
      const successCopy = categorizationManager.getCashFlowCategoryRulesCreationSuccessCopy(rules);
      toastFlashMessages.toastSuccess(successCopy);
    },
    onError: () => {
      toastFlashMessages.toastError(formatMessage({ id: 'toasts.counterparty.failed' }));
    },
  });
};
