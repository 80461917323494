import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Layout } from '../layout';

interface FraudProps {
  onClick: () => void;
}

export function Fraud({ onClick }: FraudProps): ReactNode {
  const { formatMessage } = useIntl();
  const zendeskLocalization = useEmberService('zendesk-localization');

  return (
    <Layout
      data-test-help-center-selected-item-claim="fraud"
      onClick={onClick}
      title={formatMessage({
        id: 'transactions.sidebar.helper.dispute.fraud.subtitle',
      })}
    >
      <FormattedMessage
        id="transactions.sidebar.helper.dispute.fraud.description.body"
        values={{
          learnMoreLink: (
            <a
              className="body-link"
              data-test-fraud-claim-link
              href={zendeskLocalization.getLocalizedArticle(9015312)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="transactions.sidebar.helper.dispute.learn-more" />
            </a>
          ),
        }}
      />
    </Layout>
  );
}
