import { type PropsWithChildren, type ReactNode } from 'react';
import cx from 'clsx';
import { Button } from 'react-aria-components';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';

interface CopyButtonProps extends PropsWithChildren {
  className?: string;
  clipboardText: string;
  onError?: () => void;
  onSuccess?: () => void;
  successLabel?: string;
}

export function CopyButton({
  className,
  clipboardText,
  children,
  onError,
  onSuccess,
  successLabel,
  ...props
}: CopyButtonProps): ReactNode {
  const clipboardManager = useEmberService('clipboardManager');

  const copy = async (): Promise<void> => {
    await clipboardManager.copyTask.perform({
      clipboardText,
      onError,
      onSuccess,
      successLabel,
    });
  };

  const handlePress = (): void => {
    void copy();
  };

  return (
    <Button
      className={cx('copy-btn', className)}
      data-clipboard-text={clipboardText}
      onPress={handlePress}
      {...props}
    >
      {children}
    </Button>
  );
}
