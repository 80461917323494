import {
  useInfiniteQuery,
  type InfiniteData,
  type UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type { BasketItem } from '@repo/domain-kit/payment-links';
import type ProductModel from 'qonto/models/product';

interface FetchProductsArgs {
  page?: number;
  perPage?: number;
  sortBy?: string;
}

export interface ProductsResponse {
  products: BasketItem[];
  meta: {
    total_count: number;
    next_page: number | null;
  };
}

export const convertProductToBasketItem = (product: ProductModel): BasketItem => {
  return {
    id: product.id,
    type: product.type,
    title: product.title,
    description: product.description,
    unitPrice: {
      value: product.unitPrice.value,
      currency: product.unitPrice.currency,
    },
    vatRate: product.vatRate,
    quantity: 1,
    measureUnit: product.unit ?? 'unit',
  };
};

export function useFetchInfinitePaymentLinkItems({
  perPage = 50,
  sortBy = 'created_at:desc',
}: Omit<FetchProductsArgs, 'page'>): UseInfiniteQueryResult<InfiniteData<ProductsResponse>> {
  const store = useEmberService('store');

  const fetchProducts = async (page: number): Promise<ProductsResponse> => {
    const products = await store.query<'product'>('product', {
      page: { number: page, size: perPage },
      sort_by: sortBy,
    });

    return {
      products: products.toArray().map(convertProductToBasketItem),
      // @ts-expect-error -- No types for ember-data meta
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- No types for ember-data meta
      meta: products.meta,
    };
  };

  return useInfiniteQuery({
    queryKey: ['infinite-products', sortBy, perPage],
    queryFn: ({ pageParam }) => fetchProducts(pageParam),
    getNextPageParam: lastPage => lastPage.meta.next_page,
    initialPageParam: 1,
    refetchOnWindowFocus: false,
  });
}
