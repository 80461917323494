import { type QueryClient, type QueryKey } from '@tanstack/react-query';
import type {
  Transaction,
  SearchTransactionsMeta,
  SearchTransactionsResult,
} from 'qonto/react/graphql';

const getActiveQueryKeyFromCache = (key: string, client: QueryClient): QueryKey | undefined => {
  return client
    .getQueryCache()
    .getAll()
    .find(query => query.observers.length > 0 && query.queryKey[0] === key)?.queryKey;
};

const getActiveTransaction = (transactionId: string, transactions: Transaction[]): Transaction => {
  const activeTransaction = transactions.find(t => t.id === transactionId);
  if (!activeTransaction) throw new Error('Transaction not found');

  return activeTransaction;
};

export const manageActiveTransactionsQueryCache = (
  transactionId: string,
  queryClient: QueryClient
): {
  activeQueryKey: QueryKey;
  meta: SearchTransactionsMeta;
  transactions: Transaction[];
  transaction: Transaction;
  updateTransactionCache: (updatedTransaction: Transaction) => void;
  bulkUpdateCache: (updatedTransactions: Transaction[]) => void;
  getTransactionsFromIds: (transactionIds: string[]) => Transaction[];
} => {
  const activeQueryKey = getActiveQueryKeyFromCache('search-transactions-graphql', queryClient);
  if (!activeQueryKey) throw new Error('Active query key not found');

  const cachedTransactionsQuery = queryClient.getQueryData(activeQueryKey);
  const { transactions, meta } = cachedTransactionsQuery as SearchTransactionsResult;
  const activeTransactions = [...transactions];
  const activeTransaction = getActiveTransaction(transactionId, activeTransactions);

  const updateTransactionCache = (updatedTransaction: Transaction): void => {
    const index = activeTransactions.findIndex(t => t.id === updatedTransaction.id);
    if (index !== -1) {
      activeTransactions[index] = updatedTransaction;
      queryClient.setQueryData(activeQueryKey, { transactions: activeTransactions, meta });
    } else {
      throw new Error(`Transaction with id ${transactionId} not found.`);
    }
  };

  const bulkUpdateCache = (updatedTransactions: Transaction[]): void => {
    if (!updatedTransactions.length) return;

    const updatedMap = new Map(updatedTransactions.map(t => [t.id, t]));
    const newTransactions = activeTransactions.map(t =>
      updatedMap.has(t.id) ? updatedMap.get(t.id) : t
    );

    queryClient.setQueryData(activeQueryKey, { transactions: newTransactions, meta });
  };

  return {
    meta,
    activeQueryKey,
    transactions: activeTransactions,
    transaction: activeTransaction,
    bulkUpdateCache,
    updateTransactionCache,
    getTransactionsFromIds: (transactionIds: string[]): Transaction[] => {
      return transactions.filter(t => transactionIds.includes(t.id));
    },
  };
};

export const helpers = {
  manageActiveTransactionsQueryCache,
};
