import type { Amount, AmountUpdatable } from './amount';
import type {
  CashflowForecastFormulaReadable,
  CashflowForecastFormulaUpdatable,
} from './cash-flow-forecast-formula';

export enum CashflowForecastEntrySource {
  Manual = 'manual',
  Formula = 'formula',
  Aggregated = 'aggregated_amount',
  Auto = 'auto',
}

export interface CashflowForecastEntry {
  amount: Amount<string>;
  source: CashflowForecastEntrySource;
  formula?: CashflowForecastFormulaReadable;
  actualPercentage?: string;
  projectedPercentage?: string;
  gapToForecast?: Amount<string>;
  isFlashForecast?: boolean;
}

export interface CashflowForecastEntryUpdatable {
  amount: AmountUpdatable;
  categoryId: string;
  month: number;
  year: number;
  formula?: CashflowForecastFormulaUpdatable;
}

export interface CashflowSideForecastEntry {
  amountSum: Amount<string>;
  actualPercentage?: string;
  projectedPercentage?: string;
  gapToForecast: Amount<string>;
}
