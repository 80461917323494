import { type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks/use-ember-service';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { DEFAULT_SORT_ORDER, SORT_ORDER } from 'qonto/constants/sort';
import { SortIcon } from 'qonto/react/components/paginated-table/header/sort';
import styles from './header.strict-module.css';

type AriaSort = 'none' | 'ascending' | 'descending';
type SortField = 'request_type' | 'initiator' | 'created_at' | 'status';
type SortOrder = (typeof SORT_ORDER)[keyof typeof SORT_ORDER];
type SortFull = `${SortField}:${SortOrder}`;

interface HeaderProps {
  sortBy?: SortFull;
  handleSortBy?: (sort: SortFull) => void;
}

export function Header({ sortBy, handleSortBy }: HeaderProps): ReactNode {
  const subscriptionManager = useEmberService('subscription-manager');
  const sortField = sortBy?.split(':')[0] as SortField;
  const sortOrder = sortBy?.split(':')[1] || DEFAULT_SORT_ORDER;
  const isAscending = sortOrder === SORT_ORDER.ASC;
  const hasBankAccountFeature = subscriptionManager.hasFeature('bank_account');

  const getAriaSort = (columnField: string): AriaSort => {
    if (columnField === sortField) {
      return sortOrder === SORT_ORDER.ASC ? 'ascending' : 'descending';
    }
    return 'none';
  };

  const handleSort = (field: SortField): void => {
    if (!handleSortBy) return;

    let order: SortOrder = DEFAULT_SORT_ORDER;

    if (field === sortField) {
      order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
    }

    handleSortBy(`${field}:${order}`);
  };

  const getColumnWidth = (baseWidth: string, adjustment: string): string => {
    return hasBankAccountFeature ? baseWidth : adjustment;
  };

  return (
    <tr data-test-reimbursements-completed-table-header>
      <th aria-hidden="true" className={cx(styles['header-cell'], styles.empty)} scope="col" />
      <th
        aria-sort={getAriaSort('request_type')}
        className={styles['header-cell']}
        data-test-table-header-request-type
        scope="col"
        style={{ width: getColumnWidth('30%', '40%') }}
      >
        <button
          className={cx(styles['header-content'], sortField === 'request_type' && styles.active)}
          onClick={() => {
            handleSort('request_type');
          }}
          type="button"
        >
          <FormattedMessage
            id={
              hasBankAccountFeature
                ? 'reimbursements.headers.type'
                : 'expense-reports.headers.expense'
            }
          />
          <SortIcon isActive={sortField === 'request_type'} isAscending={isAscending} />
        </button>
      </th>
      {hasBankAccountFeature ? (
        <th
          aria-sort={getAriaSort('initiator')}
          className={styles['header-cell']}
          data-test-table-header-request-initiator
          scope="col"
          style={{ width: '30%' }}
        >
          <button
            className={cx(styles['header-content'], sortField === 'initiator' && styles.active)}
            onClick={() => {
              handleSort('initiator');
            }}
            type="button"
          >
            <FormattedMessage id="reimbursements.headers.requester" />
            <SortIcon isActive={sortField === 'initiator'} isAscending={isAscending} />
          </button>
        </th>
      ) : null}
      <th
        aria-sort={getAriaSort('created_at')}
        className={styles['header-cell']}
        data-test-table-header-request-date
        scope="col"
        style={{ width: getColumnWidth('10%', '30%') }}
      >
        <button
          className={cx(styles['header-content'], sortField === 'created_at' && styles.active)}
          onClick={() => {
            handleSort('created_at');
          }}
          type="button"
        >
          <FormattedMessage
            id={
              hasBankAccountFeature
                ? 'reimbursements.headers.request-date'
                : 'expense-reports.headers.upload-date'
            }
          />
          <SortIcon isActive={sortField === 'created_at'} isAscending={isAscending} />
        </button>
      </th>
      {hasBankAccountFeature ? (
        <th
          aria-sort={getAriaSort('status')}
          className={cx(styles['header-cell'], styles['header-status'])}
          data-test-table-header-request-status
          scope="col"
          style={{ width: '130px' }}
        >
          <button
            className={cx(styles['header-content'], sortField === 'status' && styles.active)}
            onClick={() => {
              handleSort('status');
            }}
            type="button"
          >
            <FormattedMessage id="reimbursements.headers.status" />
            <SortIcon isActive={sortField === 'status'} isAscending={isAscending} />
          </button>
        </th>
      ) : null}
      <th
        className={cx(styles['header-cell'], styles.amount)}
        data-test-table-header-request-amount
        scope="col"
        style={{ width: getColumnWidth('20%', '30%') }}
      >
        <div className={styles['header-content']}>
          <FormattedMessage id="reimbursements.headers.amount" />
        </div>
      </th>
      <th aria-hidden="true" className={cx(styles['header-cell'], styles.empty)} scope="col" />
    </tr>
  );
}
