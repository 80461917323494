import { type PropsWithChildren, type ReactNode, useState } from 'react';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { Badge, Disclaimer, SelectorToggle, TextField } from '@repo/design-system-kit';
// @ts-expect-error The file needs to be migrated to TypeScript
import { ErrorInfo } from 'qonto/utils/error-info';
import { Addons } from 'qonto/constants/addons.ts';
import { ESignaturePromoPopup } from '../promotions/esignature-promo-popup';
import styles from './quote-esignature.strict-module.css';

interface QuoteEsignatureProps extends PropsWithChildren {
  clientDetails: ClientDetails;
  errors: {
    firstName: boolean;
    lastName: boolean;
    email: {
      empty: boolean;
      invalid: boolean;
    };
  };
  isClientEnabled: boolean;
  isIssuerEnabled: boolean;
  handleClientToggleChange: () => void;
  handleIssuerToggleChange: () => void;
  updateClientDetails: (value: ClientDetails) => void;
}

interface ClientDetails {
  firstName?: string;
  lastName?: string;
  email?: string;
}

interface UserManager {
  currentUser: {
    firstName: string;
    lastName: string;
  } | null;
}

interface ErrorInfoType {
  shouldSendToSentry: boolean;
  for: (error: Error) => ErrorInfoType;
}

export function QuoteEsignature({
  clientDetails,
  errors,
  isClientEnabled,
  isIssuerEnabled,
  handleClientToggleChange,
  handleIssuerToggleChange,
  updateClientDetails,
  ...props
}: QuoteEsignatureProps): ReactNode {
  const { formatMessage } = useIntl();
  const userManager = useEmberService('userManager') as UserManager;
  const subscriptionManager = useEmberService('subscription-manager');
  const upsellManager = useEmberService('upsell-manager');
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const segment = useEmberService('segment');
  const sentry = useEmberService('sentry');
  const zendeskLocalization = useEmberService('zendesk-localization');
  const hasArAddOn = Boolean(subscriptionManager.hasAddon(Addons.AccountsReceivable));
  const hasAvailableFreeTrial = Boolean(
    upsellManager.hasAvailableFreeTrial('ar_quotes_esignature')
  );
  const hasBankAccount = subscriptionManager.hasFeature('bank_account');
  const hasInitialTrial = Boolean(subscriptionManager.currentSubscription?.hasInitialTrial);
  const isFreeTrialActive = hasAvailableFreeTrial || hasInitialTrial;
  const [localClientDetails, setLocalClientDetails] = useState(clientDetails);
  const [localClientDetailsErrors, setLocalClientDetailsErrors] = useState(errors);
  const { firstName, lastName, email } = localClientDetails;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const requiredFieldError = formatMessage({
    id: 'receivable-invoices.share-email.required-field',
  });
  const emailFieldError = localClientDetailsErrors.email.empty
    ? requiredFieldError
    : formatMessage({
        id: 'receivable-invoices.share-email.field-send-to.error',
      });
  let clientName;
  if (firstName && lastName) {
    clientName = `${firstName} ${lastName}`;
  } else {
    clientName = formatMessage({
      id: 'receivable-invoices.quote-sending.e-signature.toggle.client.generic',
    });
  }
  const userFirstName = userManager.currentUser?.firstName;
  const userLastName = userManager.currentUser?.lastName;
  const userName = `${userFirstName} ${userLastName}`;

  const openModal = (): void => {
    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    setIsModalOpen(false);
  };

  const isNew = (): boolean => {
    const currentDate = new Date();
    const targetDate = new Date('2025-05-15T23:59:59');

    return currentDate <= targetDate;
  };

  const handleUpdateLocalClientDetails = (key: string, value: string): void => {
    setLocalClientDetails({ ...localClientDetails, [key]: value });
    updateClientDetails({ ...localClientDetails, [key]: value });
    if (key === 'email') {
      setLocalClientDetailsErrors({
        ...localClientDetailsErrors,
        email: { empty: false, invalid: false },
      });
    } else {
      setLocalClientDetailsErrors({ ...localClientDetailsErrors, [key]: false });
    }
  };

  const renderBadge = (): ReactNode | null => {
    if (!hasArAddOn && !isFreeTrialActive) {
      segment.track('electronic-signature_add', {
        value: 'not-available',
      });

      return (
        <button onClick={openModal} type="button">
          <Badge className="ml-8" data-test-upsell-badge type="upgrade" />
        </button>
      );
    } else if (!hasArAddOn && hasBankAccount) {
      segment.track('electronic-signature_add', {
        value: 'not-available',
      });

      return (
        <button onClick={openModal} type="button">
          <Badge className="ml-8" data-test-free-trial-badge type="tryForFree" />
        </button>
      );
    } else if (isNew()) {
      return (
        <Badge
          className="ml-8"
          data-test-new-badge
          text={formatMessage({ id: 'promotion-system.badge.new.label' })}
          type="newFeature"
        />
      );
    }
    return null;
  };

  const handleCtaClick = async (): Promise<void> => {
    try {
      await upsellManager.upgradeRecommendationWithTransition('ar_quotes_esignature');
      closeModal();
    } catch (error) {
      toastFlashMessages.toastError(formatMessage({ id: 'toasts.errors.server_error' }));

      if ((ErrorInfo as ErrorInfoType).for(error as Error).shouldSendToSentry) {
        sentry.captureException(error);
      }
    }
  };

  const onClientToggleChange = (): void => {
    handleClientToggleChange();
    segment.track('electronic-signature_enabled', {
      signer: 'client',
    });
  };

  const onIssuerToggleChange = (): void => {
    handleIssuerToggleChange();
    segment.track('electronic-signature_enabled', {
      signer: 'issuer',
    });
  };

  return (
    <div data-test-quote-esignature {...props}>
      <h2 className="title-2 mb-4" data-test-quote-esignature-title>
        <span>{formatMessage({ id: 'receivable-invoices.quote-sending.e-signature.title' })}</span>
        {renderBadge()}

        {Boolean(isModalOpen) && (
          <ESignaturePromoPopup
            hasFreeTrial={isFreeTrialActive}
            isOpen={isModalOpen}
            onClose={closeModal}
            onConfirm={handleCtaClick}
          />
        )}
      </h2>
      <p className="body-1 mb-16" data-test-quote-esignature-subtitle>
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx -- Needed for proper spacing between text and link */}
        {formatMessage({ id: 'receivable-invoices.quote-sending.e-signature.subtitle' })}{' '}
        <a
          className="body-link"
          data-test-quote-esignature-subtitle-link
          href={zendeskLocalization.getLocalizedArticle('quotes-esignature')}
          rel="noopener noreferrer"
          target="_blank"
        >
          {formatMessage({ id: 'receivable-invoices.quote-sending.e-signature.faq-text' })}
        </a>
      </p>
      <div className={styles['toggle-wrapper']}>
        <SelectorToggle
          checked={isClientEnabled}
          className={cx('mb-16', styles['client-toggle'])}
          content={
            <div className={styles['client-toggle-content']}>
              <Disclaimer.Inline data-test-quote-esignature-client-disclaimer level="info">
                {formatMessage({
                  id: 'receivable-invoices.quote-sending.e-signature.disclaimer',
                })}
              </Disclaimer.Inline>
              <span className={styles['client-toggle-name-fields']}>
                <TextField
                  data-test-quote-esignature-client-first-name
                  defaultValue={firstName}
                  errorMessage={requiredFieldError}
                  isInvalid={localClientDetailsErrors.firstName}
                  label={formatMessage({
                    id: 'receivable-invoices.quote-sending.e-signature.toggle.client.first-name',
                  })}
                  onChange={value => {
                    handleUpdateLocalClientDetails('firstName', value);
                  }}
                  placeholder={formatMessage({
                    id: 'receivable-invoices.customer-creation.first-name.placeholder',
                  })}
                />
                <TextField
                  data-test-quote-esignature-client-last-name
                  defaultValue={lastName}
                  errorMessage={requiredFieldError}
                  isInvalid={localClientDetailsErrors.lastName}
                  label={formatMessage({
                    id: 'receivable-invoices.quote-sending.e-signature.toggle.client.last-name',
                  })}
                  onChange={value => {
                    handleUpdateLocalClientDetails('lastName', value);
                  }}
                  placeholder={formatMessage({
                    id: 'receivable-invoices.customer-creation.last-name.placeholder',
                  })}
                />
              </span>
              <TextField
                data-test-quote-esignature-client-email
                defaultValue={email}
                errorMessage={emailFieldError}
                isInvalid={
                  localClientDetailsErrors.email.empty || localClientDetailsErrors.email.invalid
                }
                label={formatMessage({
                  id: 'receivable-invoices.quote-sending.e-signature.toggle.client.email',
                })}
                onChange={value => {
                  handleUpdateLocalClientDetails('email', value);
                }}
                placeholder={formatMessage({
                  id: 'receivable-invoices.share-email.field-send-to.email-example.placeholder',
                })}
                type="email"
              />
            </div>
          }
          data-test-quote-esignature-client-toggle
          isDisabled={!hasArAddOn}
          onChange={onClientToggleChange}
          title={clientName}
          {...props}
        />
        <SelectorToggle
          checked={isIssuerEnabled}
          className={styles['user-toggle']}
          data-test-quote-esignature-user-toggle
          isDisabled={!isClientEnabled || !hasArAddOn}
          onChange={onIssuerToggleChange}
          title={formatMessage(
            { id: 'receivable-invoices.quote-sending.e-signature.toggle.user' },
            { userName }
          )}
          {...props}
        />
        {Boolean(!hasArAddOn) && (
          <button
            aria-label={formatMessage({ id: 'empty-states.system.try.cta' })}
            className={styles['hidden-popup-btn']}
            data-test-quote-esignature-hidden-btn
            onClick={openModal}
            type="button"
          />
        )}
      </div>
    </div>
  );
}
