import { type ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Breadcrumbs as DualNavBreadcrumbs } from 'qonto/react/shared/components/dual-nav/breadcrumbs';
import styles from './page-header.strict-module.css';

interface PageHeaderProps {
  title: ReactNode;
  className?: string;
  loading?: boolean;
  link?: ReactNode;
  badge?: ReactNode;
  actions?: ReactNode;
  subtitle?: ReactNode;
}

export function PageHeader({
  title,
  className = '',
  loading,
  link,
  badge,
  actions,
  subtitle,
}: PageHeaderProps): ReactNode {
  const menu = useEmberService('menu');

  return (
    <header className={className} data-test-page-header id="main-content">
      {link}
      {menu.breadcrumbsTrail.length > 0 ? (
        <DualNavBreadcrumbs trail={menu.breadcrumbsTrail} />
      ) : null}
      <div className={styles['page-header']}>
        <section className={styles['section-title']}>
          <h1 className={styles.title} data-test-page-header-title>
            {title}
          </h1>
          {badge}
        </section>
        <span className={styles.spacer} />
        {loading ? (
          <SkeletonLoader.Block
            borderRadius="4px"
            data-test-page-header-actions-placeholder=""
            height="40px"
            width="100px"
          />
        ) : (
          actions
        )}
      </div>
      {subtitle}
    </header>
  );
}
