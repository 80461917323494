// source: https://github.com/TanStack/table/discussions/2498#discussioncomment-8649218
import type { TableState } from '@tanstack/react-table';
import { useRef, useEffect } from 'react';
import { DEFAULT_COLUMN_WIDTH } from 'qonto/constants/table-view';

export const useResizeObserver = (
  state: TableState,
  callback: (columnId: string, columnSize: number) => void
): void => {
  const columnResizeRef = useRef<string | false>();

  useEffect(() => {
    if (!state.columnSizingInfo.isResizingColumn && columnResizeRef.current) {
      const newColumnSize = state.columnSizing[columnResizeRef.current] ?? DEFAULT_COLUMN_WIDTH;

      callback(columnResizeRef.current, newColumnSize);
    }
    columnResizeRef.current = state.columnSizingInfo.isResizingColumn;
  }, [callback, state.columnSizingInfo, state.columnSizing]);
};
