import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { supplierHubNamespace } from 'qonto/constants/hosts';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
import { camelizeKeys } from '../api/utils/camelize-keys';
import { useFetchApi } from './use-fetch-api';

export function useFetchSupplierInvoice(
  invoiceId: string,
  onError?: () => void,
  options = {}
): UseQueryResult<SupplierInvoiceModel> {
  const fetchApi = useFetchApi();

  return useQuery({
    queryKey: ['supplier-invoice', invoiceId],
    queryFn: async () => {
      const response = await fetchApi(`${supplierHubNamespace}/${invoiceId}`);

      if (!response.ok) {
        if (onError) {
          onError();
        } else {
          throw new Error('Failed to fetch supplier invoice');
        }
      }

      const { supplier_invoice: supplierInvoice } = (await response.json()) as {
        supplier_invoice: SupplierInvoiceModel;
      };

      return camelizeKeys(supplierInvoice);
    },
    ...options,
  });
}
