import { Badge } from '@repo/design-system-kit';
import type { PaymentMethods as PaymentLinkMethod } from '@repo/domain-kit/shared';
import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PaymentMethodsDisclaimer,
  PaymentMethodsOnboarding,
  PaymentMethodsSelection,
} from './internal';
import styles from './styles.strict-module.css';

export interface PaymentMethodsProps {
  // Whether the organization's Mollie connection status is "not_connected"
  isMollieStatusNotConnected: boolean;
  // The payment methods enabled on the organization's Mollie account
  enabledPaymentMethods: { method: PaymentLinkMethod }[];
  // The payment methods selected by the user for the current invoice
  selectedPaymentMethods: PaymentLinkMethod[];
  // Whether to show the pending disclaimer
  shouldShowPendingDisclaimer: boolean;
  // Whether to show the disabled disclaimer
  shouldShowDisabledDisclaimer: boolean;
  // Whether to show the disabled payment methods disclaimer
  shouldShowDisabledMethodsDisclaimer: boolean;
  // The function to call when a payment method is toggled
  onPaymentMethodToggle: (method: PaymentLinkMethod) => void;
  // The function to call when the user clicks the CTA to activate payment links
  onActivatePaymentLinksClick: () => void;
}

export function PaymentMethods({
  enabledPaymentMethods,
  isMollieStatusNotConnected,
  onPaymentMethodToggle,
  selectedPaymentMethods,
  onActivatePaymentLinksClick,
  shouldShowPendingDisclaimer,
  shouldShowDisabledDisclaimer,
  shouldShowDisabledMethodsDisclaimer,
}: PaymentMethodsProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <section className={styles['payment-methods']}>
      <h2 className="title-2" data-test-methods-header>
        <span>
          <FormattedMessage id="receivable-invoices.payment-link-creation.payment-methods" />
        </span>
        {isMollieStatusNotConnected ? (
          <Badge
            className="ml-8"
            data-test-new-badge
            text={formatMessage({ id: 'menu.labels.new' })}
            type="newFeature"
          />
        ) : null}
      </h2>

      {isMollieStatusNotConnected ? (
        <PaymentMethodsOnboarding onActivatePaymentLinksClick={onActivatePaymentLinksClick} />
      ) : null}

      {!isMollieStatusNotConnected ? (
        <PaymentMethodsSelection
          enabledPaymentMethods={enabledPaymentMethods}
          onPaymentMethodToggle={onPaymentMethodToggle}
          selectedPaymentMethods={selectedPaymentMethods}
        />
      ) : null}

      <PaymentMethodsDisclaimer
        shouldShowDisabledDisclaimer={shouldShowDisabledDisclaimer}
        shouldShowDisabledMethodsDisclaimer={shouldShowDisabledMethodsDisclaimer}
        shouldShowPendingDisclaimer={shouldShowPendingDisclaimer}
      />
    </section>
  );
}
