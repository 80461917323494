import { type ReactNode } from 'react';
import cx from 'clsx';
import { LottiePlayer, ErrorState as GenericError } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import { ERROR_TYPES } from 'qonto/react/receivable-invoices/constants';
import styles from './styles.strict-module.css';

interface ErrorStateProps {
  type?: typeof ERROR_TYPES.GENERIC | typeof ERROR_TYPES.NO_RESULT;
}

interface ErrorDetails {
  lottiePath: string;
  title: string;
  subtitle: string;
}

export function ErrorState({ type }: ErrorStateProps): ReactNode {
  const { formatMessage } = useIntl();
  const error404Path = useThemedAssetPath('/lotties/error-404.json');
  const noResultPath = useThemedAssetPath('/lotties/empty-state/search-no-result.json');

  const getErrorDetails = (errorType: ErrorStateProps['type']): ErrorDetails => {
    if (errorType === ERROR_TYPES.NO_RESULT) {
      return {
        lottiePath: noResultPath,
        title: formatMessage({
          id: 'receivable-invoices.customer-creation.search-form.errors.no-result.title',
        }),
        subtitle: formatMessage({
          id: 'receivable-invoices.customer-creation.search-form.errors.no-result.body',
        }),
      };
    }

    return {
      lottiePath: error404Path,
      title: formatMessage({
        id: 'receivable-invoices.customer-creation.search-form.errors.generic.title',
      }),
      subtitle: formatMessage({
        id: 'receivable-invoices.customer-creation.search-form.errors.generic.body',
      }),
    };
  };

  const { lottiePath, title, subtitle } = getErrorDetails(type);

  return (
    <div className={styles.wrapper} data-testid={`error-state-${type}`}>
      {type === ERROR_TYPES.GENERIC ? (
        <GenericError headingLevel="h3" subtitle={subtitle} title={title} />
      ) : (
        <>
          <LottiePlayer className={styles.animation} path={lottiePath} />
          <h3 className={cx('title-3', styles.title)}>{title}</h3>
          <p className={cx('body-1', styles.subtitle)}>{subtitle}</p>
        </>
      )}
    </div>
  );
}
