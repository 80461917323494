import { useEmberService, useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  CLAIM_OUTDATED_TRESHOLD_ITALIAN_FRAUD,
  CLAIM_TYPE_OUTDATED_THRESHOLDS,
  CLAIM_TYPES,
} from 'qonto/constants/transactions';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type {
  ClaimProps,
  FlowLinkManagerService,
} from '../help-center/components/selected-item/claim/types';

interface UseClaimReturn {
  claimType: {
    isAtmClaim: boolean;
    isCommercialClaim: boolean;
    isFraudClaim: boolean;
    isPending: boolean;
    isDisputed: boolean;
    isProcessing: boolean;
    isOutdated: boolean;
    isFailing: boolean;
  };
  getErrorText: () => string;
  getValidityPeriodText: () => string;
  handleDisputeStart: () => Promise<void>;
}

export function useClaim({
  selectedClaimType,
  highlightedItem,
  onClose,
}: ClaimProps): UseClaimReturn {
  const intl = useIntl();
  const segment = useSegment();
  const router = useRouter();
  const queryParams = router.query;
  const flowLinkManager = useEmberService('flow-link-manager') as unknown as FlowLinkManagerService;
  const { organization } = useOrganizationManager();

  const getErrorText = useCallback(() => {
    if (highlightedItem?.canceled) {
      return intl.formatMessage({
        id: 'transactions.sidebar.helper.dispute.errors.fail-statuses.description.status.canceled',
      });
    } else if (highlightedItem?.declined) {
      return intl.formatMessage({
        id: 'transactions.sidebar.helper.dispute.errors.fail-statuses.description.status.declined',
      });
    } else if (highlightedItem?.reversed) {
      return intl.formatMessage({
        id: 'transactions.sidebar.helper.dispute.errors.fail-statuses.description.status.reverted',
      });
    }
    return '';
  }, [highlightedItem, intl]);

  const isFraudClaim = selectedClaimType === CLAIM_TYPES.fraud;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- organization is suppsoed to be non nullable but it sometimes is null
  const isItalianFraudCase = organization?.isItalian && isFraudClaim;

  const getValidityPeriodText = useCallback((): string => {
    if (isItalianFraudCase) {
      return intl.formatMessage({
        id: 'transactions.sidebar.helper.dispute.errors.too-old.description.italian-validity-days',
      });
    }
    return intl.formatMessage({
      id: isFraudClaim
        ? 'transactions.sidebar.helper.dispute.errors.too-old.description.validity-weeks'
        : 'transactions.sidebar.helper.dispute.errors.too-old.description.validity-days',
    });
  }, [intl, isItalianFraudCase, isFraudClaim]);

  const handleDisputeStart = useCallback(async () => {
    segment.track('cards-chargeback_start-dispute-cta_clicked', {
      type: selectedClaimType,
    });

    const card = await highlightedItem?.subject;
    let firstStepId = 'transactions-selection';

    if (isFraudClaim) {
      if (card?.isActive) {
        firstStepId = 'block-card';
      } else if (card?.isPhysical) {
        firstStepId = 'card-possession';
      }
    }

    await flowLinkManager.transitionTo({
      name: 'chargeback-claim',
      stepId: firstStepId,
      queryParams: {
        ...queryParams,
        transactionId: highlightedItem?.id,
        claimType: selectedClaimType,
      },
    });

    onClose?.();
  }, [
    segment,
    selectedClaimType,
    highlightedItem?.subject,
    highlightedItem?.id,
    isFraudClaim,
    flowLinkManager,
    queryParams,
    onClose,
  ]);

  const isPendingClaim = (highlightedItem?.chargebackDisputingTypes?.length ?? 0) > 0;

  const isDisputedClaim = (() => {
    const isDisputedForType =
      selectedClaimType && highlightedItem?.chargebackDisputedTypes?.includes(selectedClaimType);
    const isApprovedClaimForAnyType = (highlightedItem?.chargebackApprovedTypes?.length ?? 0) > 0;
    return isDisputedForType || isApprovedClaimForAnyType;
  })();

  const isPending = Boolean(highlightedItem?.pending);
  const shouldShowProcessingScreen = selectedClaimType !== CLAIM_TYPES.fraud;

  const isOutdated = (() => {
    const displayedDay = dayjs(highlightedItem?.displayAt);
    const threshold = isItalianFraudCase
      ? CLAIM_OUTDATED_TRESHOLD_ITALIAN_FRAUD
      : CLAIM_TYPE_OUTDATED_THRESHOLDS[selectedClaimType || ''];

    return dayjs().diff(displayedDay, 'day') > (threshold || 0);
  })();

  const isFailing =
    Boolean(highlightedItem?.canceled) ||
    Boolean(highlightedItem?.declined) ||
    Boolean(highlightedItem?.reversed);

  const claimType = {
    isAtmClaim: selectedClaimType === CLAIM_TYPES.atm,
    isCommercialClaim: selectedClaimType === CLAIM_TYPES.commercial,
    isFraudClaim,
    isPending: isPendingClaim,
    isDisputed: isDisputedClaim,
    isProcessing: isPending && shouldShowProcessingScreen,
    isOutdated,
    isFailing,
  };

  return {
    claimType,
    getErrorText,
    getValidityPeriodText,
    handleDisputeStart,
  };
}
