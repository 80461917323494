import { useState, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { TextAreaField, Toggle } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

interface ProductsFormProps {
  header: string;
  footer: string;
  saveMessages: boolean;
  onHeaderChange: (value: string) => void;
  onFooterChange: (value: string) => void;
  onToggleSaveMessages: () => void;
  isQuote?: boolean;
}

export function ProductsForm({
  header,
  footer,
  saveMessages,
  onHeaderChange,
  onFooterChange,
  onToggleSaveMessages,
  isQuote,
}: ProductsFormProps): ReactNode {
  const [headerValue, setHeaderValue] = useState(header);
  const [footerValue, setFooterValue] = useState(footer);

  const handleHeaderChange = (value: string): void => {
    setHeaderValue(value);
    onHeaderChange(value);
  };

  const handleFooterChange = (value: string): void => {
    setFooterValue(value);
    onFooterChange(value);
  };

  const { formatMessage } = useIntl();
  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <h3 className="title-3" data-testid="custom-messages-title">
          {formatMessage({ id: 'receivable-invoices.invoice-creation.custom-messsages.title' })}
        </h3>
        <TextAreaField
          data-testid="custom-messages-header"
          isOptional
          label={formatMessage({
            id: 'receivable-invoices.invoice-creation.invoice-details.header.label',
          })}
          maxLength={50000}
          name="header"
          onChange={handleHeaderChange}
          placeholder={formatMessage({
            id: 'receivable-invoices.invoice-creation.invoice-details.header.placeholder',
          })}
          value={headerValue}
        />
        <TextAreaField
          data-testid="custom-messages-footer"
          isOptional
          label={formatMessage({
            id: 'receivable-invoices.onboarding.customization.additional-notes.label',
          })}
          maxLength={50000}
          name="additional-notes"
          onChange={handleFooterChange}
          placeholder={formatMessage({
            id: 'receivable-invoices.onboarding.customization.additional-notes.placeholder',
          })}
          value={footerValue}
        />

        <div className={styles.toggle}>
          <Toggle
            aria-labelledby="save-messages"
            checked={saveMessages}
            data-testid="save-messages-toggle"
            onChange={onToggleSaveMessages}
          />
          <label data-testid="save-messages-label" id="save-messages">
            {formatMessage({
              id: isQuote
                ? 'receivable-invoices.quote-creation.custom-messsages.save-button-label'
                : 'receivable-invoices.invoice-creation.custom-messsages.save-button-label',
            })}
          </label>
        </div>
      </section>
    </div>
  );
}
