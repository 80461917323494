import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Layout } from '../layout';

export function PendingClaim(): ReactNode {
  const { formatMessage } = useIntl();
  const zendeskLocalization = useEmberService('zendesk-localization');

  return (
    <Layout
      data-test-help-center-selected-item-claim="pending"
      title={formatMessage({
        id: 'transactions.sidebar.helper.dispute.errors.disputing.title',
      })}
    >
      <FormattedMessage
        id="transactions.sidebar.helper.dispute.errors.disputing.description.body"
        values={{
          learnMoreLink: (
            <a
              className="body-link"
              data-test-pending-claim-link
              href={zendeskLocalization.getLocalizedArticle(9015327)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="transactions.sidebar.helper.dispute.learn-more" />
            </a>
          ),
        }}
      />
    </Layout>
  );
}
