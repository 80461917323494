import CURRENCIES from 'qonto/constants/currencies';

type SignusOption = '+' | '-' | boolean;

type FormatNumberOptions = Omit<Intl.NumberFormatOptions, 'signDisplay' | 'useGrouping'>;

interface FormatMoneyOptions extends FormatNumberOptions {
  locale: string;
  signus?: SignusOption;
  displayUndefined?: boolean;
}

const UNDEFINED_VALUE_PLACEHOLDER = '--';

const DEFAULT_MONEY_OPTIONS: FormatNumberOptions = {
  style: 'currency',
  minimumFractionDigits: 2,
  currency: CURRENCIES.default,
};

/**
 * Formats a number into a currency string with optional sign prefix
 *
 * Heavily inspired by apps/qonto-js/app/services/intl.js for API compatibility.
 *
 * @returns Formatted currency string
 * @throws Error if 'style' option is provided
 *
 * @example
 * formatMoney(42.5, \{ currency: 'EUR', signus: true \}) // '+42.50 EUR'
 * formatMoney(10, \{ currency: 'USD', signus: '-' \})    // '-10.00 USD'
 * formatMoney(-10, \{ currency: 'GBP', signus: true \})  // '-10.00 GBP'
 * formatMoney(42, \{ displayUndefined: true \})          // '--'
 * formatMoney(Infinity)                                  // '--'
 */
export function formatMoney(amount: number | undefined, options: FormatMoneyOptions): string {
  validateInput(options);

  const { locale, signus, displayUndefined, ...formatOptions }: FormatMoneyOptions = {
    ...DEFAULT_MONEY_OPTIONS,
    ...removeUndefinedOptions(options),
  };

  if (!Number.isFinite(amount) || displayUndefined) {
    return UNDEFINED_VALUE_PLACEHOLDER;
  }

  const value = amount ?? 0;
  const displaySign = getDisplaySign(value, signus);
  const formattedValue = formatNumber(value, locale, formatOptions);

  // \xA0 is a non-breaking space character
  return displaySign
    ? `${displaySign}\xA0${formattedValue}\xA0${formatOptions.currency}`
    : `${formattedValue}\xA0${formatOptions.currency}`;
}

function validateInput(options: FormatMoneyOptions): void {
  if (options.style) {
    throw new Error('`style` option must not be used when calling `formatMoney`');
  }

  if (!options.locale) {
    throw new Error('`locale` option must be provided when calling `formatMoney`');
  }
}

function getDisplaySign(value: number, signus?: SignusOption): string | undefined {
  if (!signus && value >= 0) return undefined;
  if (signus === true) return value < 0 ? '-' : '+';

  return signus || (value < 0 ? '-' : undefined);
}

function formatNumber(value: number, locale: string, options: FormatNumberOptions): string {
  const excludedTypes = ['minusSign', 'currency', 'literal'];

  return Intl.NumberFormat(locale, options)
    .formatToParts(value)
    .filter(part => !excludedTypes.includes(part.type))
    .map(part => part.value)
    .join('');
}

function removeUndefinedOptions(obj: Partial<FormatMoneyOptions>): FormatMoneyOptions {
  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Avoid false positives
    Object.entries(obj).filter(([_, v]) => v !== undefined)
  ) as unknown as FormatMoneyOptions;
}
