import React, { useRef, useState } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { ContentCategory as DKContentCategory } from '@repo/domain-kit/accounts-receivable';
import {
  ColumnId,
  type Column,
  type ReceivableInvoicesSettingsModel,
} from 'qonto/react/receivable-invoices/api/models/receivable-invoices-settings';

interface ContentCategoryProps {
  settings: ReceivableInvoicesSettingsModel;
  isDisabled: boolean;
}

const defaultColumns = [
  { id: ColumnId.Description },
  { id: ColumnId.Quantity },
  { id: ColumnId.UnitPrice },
  { id: ColumnId.VatRate },
  { id: ColumnId.Subtotal },
];

export function ContentCategory({ settings, isDisabled }: ContentCategoryProps): React.ReactNode {
  const [columns, setColumns] = useState(settings.tableColumns ?? defaultColumns);
  const hasTrackedEvent = useRef(false);
  const segment = useEmberService('segment');

  const trackEvent = (): void => {
    if (!hasTrackedEvent.current) {
      segment.track('content_customization-clicked', { availability: 'included' });
      hasTrackedEvent.current = true;
    }
  };

  const updateTableColumns = (newColumns: Column[]): void => {
    settings.tableColumns = newColumns;
    setColumns(newColumns);
  };

  const handleInvoiceTitleChange = (value: string): void => {
    settings.invoiceTitle = value;
    trackEvent();
  };
  const handleQuoteTitleChange = (value: string): void => {
    settings.quoteTitle = value;
    trackEvent();
  };
  const handleColumNamesSort = (sortedColumns: Column[]): void => {
    updateTableColumns(sortedColumns);
    trackEvent();
  };
  const handleColumNamesInputChange = ({
    id,
    value,
  }: {
    id: Column['id'];
    value: string;
  }): void => {
    const updatedColumns = columns.map(column =>
      column.id === id ? { ...column, label: value } : column
    );
    updateTableColumns(updatedColumns);
    trackEvent();
  };

  return (
    <DKContentCategory
      invoiceTitle={settings.invoiceTitle}
      isDisabled={isDisabled}
      items={columns}
      onColumnInputChange={handleColumNamesInputChange}
      onInvoiceTitleChange={handleInvoiceTitleChange}
      onQuoteTitleChange={handleQuoteTitleChange}
      onSort={handleColumNamesSort}
      quoteTitle={settings.quoteTitle}
    />
  );
}
