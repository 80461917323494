import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks/use-ember-service';
import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { transactionNamespace } from 'qonto/constants/hosts';
import type { Transaction } from 'qonto/react/models/transaction';
import { useFetchApi } from '../use-fetch-api';
import { useRefetchTransaction } from './use-refetch-transaction';

export const useUpdateAttachmentStatus = (
  transactionId: string
): {
  isRefetchingTransaction: boolean;
  markAsLost: UseMutationResult<void, Error, void>;
  markAsRetrieved: UseMutationResult<void, Error, void>;
  markAsRequired: UseMutationResult<void, Error, void>;
  markAsNotRequired: UseMutationResult<void, Error, void>;
} => {
  const { mutate: refetchTransaction, isPending: isRefetchingTransaction } =
    useRefetchTransaction(transactionId);
  const fetchApi = useFetchApi();
  const store = useEmberService('store');

  const createMutationFn = (endpoint: string, errorMessage: string) => {
    return async () => {
      try {
        const response = await fetchApi(endpoint, { method: 'PUT' });
        if (!response.ok) {
          throw new Error(errorMessage);
        }

        const transaction = (await response.json()) as { transaction: Transaction };

        store.pushPayload(transaction);
      } catch (e) {
        throw new Error(errorMessage);
      }
    };
  };

  return {
    markAsLost: useMutation({
      mutationFn: createMutationFn(
        `${transactionNamespace}/transactions/${transactionId}/mark_attachment_as_lost`,
        'Could not mark as lost'
      ),
      onSuccess: () => {
        refetchTransaction();
      },
    }),
    markAsRetrieved: useMutation({
      mutationFn: createMutationFn(
        `${transactionNamespace}/transactions/${transactionId}/unmark_attachment_as_lost`,
        'Could not mark as retrieved'
      ),
      onSuccess: () => {
        refetchTransaction();
      },
    }),
    markAsRequired: useMutation({
      mutationFn: createMutationFn(
        `${transactionNamespace}/transactions/${transactionId}/mark_attachment_as_required`,
        'Could not mark as required'
      ),
      onSuccess: () => {
        refetchTransaction();
      },
    }),
    markAsNotRequired: useMutation({
      mutationFn: createMutationFn(
        `${transactionNamespace}/transactions/${transactionId}/unmark_attachment_as_required`,
        'Could not mark as not required'
      ),
      onSuccess: () => {
        refetchTransaction();
      },
    }),
    isRefetchingTransaction,
  };
};
