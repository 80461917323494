import { useQueryClient } from '@tanstack/react-query';
import { manageActiveTransactionsQueryCache } from 'qonto/react/utils/react-query-helpers';
import { bulkSelectionManager } from 'qonto/react/contexts/bulk-selection-context';
import type { Transaction } from 'qonto/react/graphql';

export const computeBulkCashflowCategory = (transactions: Transaction[]): string | null => {
  const [firstTransaction] = transactions;
  const firstCategoryId = firstTransaction?.categoryAssignment?.id;
  const areAllSameCategory = transactions.every(
    transaction => transaction.categoryAssignment?.id === firstCategoryId
  );
  const activeCategoryId = areAllSameCategory ? (firstCategoryId ?? null) : null;
  return activeCategoryId;
};

export const useBulkCashflowCategory = (): { cashflowCategory: string | null } => {
  const queryClient = useQueryClient();
  const { selection: selectedTransactionIds } = bulkSelectionManager.useBulkSelection();

  if (!selectedTransactionIds[0]) return { cashflowCategory: null };

  const { getTransactionsFromIds } = manageActiveTransactionsQueryCache(
    selectedTransactionIds[0],
    queryClient
  );
  const selectedTransactions = getTransactionsFromIds(selectedTransactionIds);

  return { cashflowCategory: computeBulkCashflowCategory(selectedTransactions) };
};

export const bulkCashflowCategoryManager = {
  useBulkCashflowCategory,
};
