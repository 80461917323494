import { useEffect, useState, type FormEvent, type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { Button, TextField } from '@repo/design-system-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { camelizeKeys } from 'qonto/react/api/utils/camelize-keys';
import { ERROR_TYPES } from 'qonto/react/receivable-invoices/constants';
import styles from './styles.strict-module.css';
import { useCompaniesSearch } from './hooks/use-companies-search';
import type { CompanyData, PrefillClientData } from './types';
import { CompanyCard } from './internal/company-card/company-card';
import { CompanyCardLoading } from './internal/company-card-loading/company-card-loading';
import { ErrorState } from './internal/error-state/error-state';

interface CompanySearchProps {
  legalCountry: string;
  onPrefillClientData: (data: PrefillClientData) => void;
  showManualEntryForm: (inputValue: string) => void;
}

export function CompanySearch({
  legalCountry,
  onPrefillClientData,
  showManualEntryForm,
}: CompanySearchProps): ReactNode {
  const segment = useEmberService('segment');

  const [inputError, setInputError] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [searchError, setSearchError] = useState<
    typeof ERROR_TYPES.GENERIC | typeof ERROR_TYPES.NO_RESULT | undefined
  >(undefined);
  const [searchResults, setSearchResults] = useState<CompanyData[] | []>([]);
  const { companiesSearch, isLoading } = useCompaniesSearch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    setTimeout(() => {
      const inputElement = document.getElementById('search-input-field') as HTMLInputElement;
      inputElement.focus();
    }, 100);
  }, []);

  const handleAddManually = (): void => {
    segment.track('client_creation-manual_creation-clicked');
    showManualEntryForm(inputValue.trim());
  };

  const handleChange = (value: string): void => {
    if (!inputValue) {
      segment.track('client_creation-search-started');
    }
    inputError && setInputError(false);
    setInputValue(value);
  };

  const handleSearch = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const query = inputValue.trim();

    if (!query) {
      setInputError(true);
      return;
    }

    try {
      searchError && setSearchError(undefined);

      segment.track('client_creation-search-clicked');

      const data: CompanyData[] = await companiesSearch({
        legalCountry,
        query,
        limit: legalCountry.toUpperCase() === 'DE' ? 50 : 10,
      });

      if (data.length === 0) {
        setSearchError(ERROR_TYPES.NO_RESULT);
        segment.track('client_creation-search-no_results');
        return;
      }

      setSearchResults(data);
    } catch (err) {
      setSearchError(ERROR_TYPES.GENERIC);
    }
  };

  return (
    <div className={styles.wrapper}>
      <form
        className={cx('mb-32', styles['search-container'])}
        onSubmit={e => {
          void handleSearch(e);
        }}
        role="search"
      >
        <TextField
          className={styles['search-input']}
          data-testid="search-input-field"
          errorMessage={inputError ? formatMessage({ id: 'validations.errors.blank' }) : undefined}
          id="search-input-field"
          isInvalid={inputError}
          label={formatMessage(
            { id: 'receivable-invoices.customer-creation.search-form.input.label' },
            {
              legalCountry,
            }
          )}
          onChange={handleChange}
          placeholder={formatMessage(
            {
              id: 'receivable-invoices.customer-creation.search-form.input.placeholder',
            },
            { legalCountry }
          )}
          type="search"
        />
        <Button
          className={cx(styles['search-btn'], inputError && styles['has-error'])}
          data-testid="search-btn"
          type="submit"
        >
          <FormattedMessage id="receivable-invoices.customer-creation.search-form.cta.submit" />
        </Button>
      </form>
      {searchResults.length > 0 && !isLoading && !searchError && (
        <div className={styles['company-list-container']} data-testid="company-list">
          {searchResults.map((company, index) => {
            const formattedValue = camelizeKeys(company);

            return (
              <CompanyCard
                billingAddress={formattedValue.billingAddress}
                // eslint-disable-next-line react/no-array-index-key -- it's the only way to get a unique key
                key={`${formattedValue.name}-${index}`}
                legalNumber={formattedValue.tinNumber ?? formattedValue.vatNumber}
                name={formattedValue.name}
                onPress={() => {
                  segment.track('client_creation-search-result_selected');
                  onPrefillClientData(formattedValue);
                }}
              />
            );
          })}
        </div>
      )}
      {Boolean(isLoading) && (
        <div className={styles['company-list-container']} data-testid="company-list-loading">
          {Array.from({ length: 5 }, (_, index) => (
            <CompanyCardLoading key={index} />
          ))}
        </div>
      )}
      {Boolean(searchError) && !isLoading && (
        <div className="mb-32">
          <ErrorState type={searchError} />
        </div>
      )}
      {(Boolean(!searchError) || Boolean(isLoading)) && (
        <div className={cx('body-1', 'mb-32', styles.divider)} data-testid="divider">
          <FormattedMessage id="supplier-invoices.add-invoices-modal.forward-by-email.or" />
        </div>
      )}
      <Button
        className={styles['manual-btn']}
        data-testid="add-manually-btn"
        onPress={() => {
          handleAddManually();
        }}
        variant="tertiary"
      >
        <FormattedMessage id="receivable-invoices.customer-creation.search-form.cta.add-manually" />
      </Button>
    </div>
  );
}
