import dayjs, { type Dayjs } from 'dayjs';
import type { ForecastFormulaFrequency } from '../api/models/cash-flow-forecast-formula';
import type { Amount } from './amount';

export enum CashflowForecastEditionScope {
  ThisMonth = 'this_month',
  ThisAndFutureMonths = 'this_and_future_months',
}

export enum CashflowForecastDeletionScope {
  ThisMonth = 'this_month',
  ThisAndFutureMonths = 'this_and_future_months',
  AllMonths = 'all_months',
}

export interface CashflowForecastFormulaBound {
  year: number;
  month: number;
}

export interface CashflowForecastFormula {
  frequencyInMonths: ForecastFormulaFrequency;
  percentageIncrement: number | null;
  sumIncrement: Amount<string> | null;
}

export interface CashflowForecastFormulaReadable extends CashflowForecastFormula {
  start: CashflowForecastFormulaBound;
  end: CashflowForecastFormulaBound;
  operation: string;
}

export interface CashflowForecastFormulaUpdatable extends CashflowForecastFormula {
  repeatUntil: CashflowForecastFormulaBound;
}

export const getFormulaBoundDate = (formulaBound: CashflowForecastFormulaBound): Dayjs => {
  return dayjs()
    .month(formulaBound.month - 1)
    .year(formulaBound.year);
};

export const getFormulaBoundDateString = (formula: CashflowForecastFormulaReadable): string => {
  return `${getFormulaBoundDate(formula.start).format('MMMM')} - ${getFormulaBoundDate(formula.end).format('MMMM YYYY')}`;
};
