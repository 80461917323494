import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_SORT_ORDER, SORT_ORDER } from 'qonto/constants/sort';
import { SortIcon } from 'qonto/react/components/paginated-table/header/sort/sort';
import styles from './past.strict-module.css';

type AriaSort = 'none' | 'ascending' | 'descending';
type SortField = 'request_type' | 'initiator' | 'status' | 'created_at';
type SortOrder = (typeof SORT_ORDER)[keyof typeof SORT_ORDER];
type SortFull = `${SortField}:${SortOrder}`;

interface PastHeaderProps {
  sortBy?: SortFull;
  handleSortBy?: (sort: SortFull) => void;
}

export function Past({ sortBy, handleSortBy }: PastHeaderProps): ReactNode {
  const sortField = sortBy?.split(':')[0] as SortField;
  const sortOrder = sortBy?.split(':')[1] || DEFAULT_SORT_ORDER;
  const isAscending = sortOrder === SORT_ORDER.ASC;

  const getAriaSort = (columnField: string): AriaSort => {
    const isCurrentFieldActive = columnField === sortField;
    if (!isCurrentFieldActive) return 'none';
    return sortOrder === SORT_ORDER.ASC ? 'ascending' : 'descending';
  };

  const handleSort = (field: SortField): void => {
    if (!handleSortBy) return;

    let currentSortOrder: SortOrder = DEFAULT_SORT_ORDER;

    if (field === sortField) {
      currentSortOrder = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
    }

    handleSortBy(`${field}:${currentSortOrder}`);
  };

  return (
    <>
      <th aria-hidden="true" className={cx(styles['header-cell'], styles.empty)} scope="col" />

      <th
        aria-sort={getAriaSort('request_type')}
        className={cx(styles['header-cell'], styles.type)}
        data-test-table-header-task-type
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'request_type' && styles.active)}
          onClick={() => {
            handleSort('request_type');
          }}
          type="button"
        >
          <FormattedMessage id="tasks.headers.type" />
          <SortIcon isActive={sortField === 'request_type'} isAscending={isAscending} />
        </button>
      </th>

      <th
        aria-sort={getAriaSort('initiator')}
        className={cx(styles['header-cell'], styles.requester)}
        data-test-table-header-task-initiator
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'initiator' && styles.active)}
          onClick={() => {
            handleSort('initiator');
          }}
          type="button"
        >
          <FormattedMessage id="tasks.headers.from" />
          <SortIcon isActive={sortField === 'initiator'} isAscending={isAscending} />
        </button>
      </th>

      <th
        aria-sort={getAriaSort('created_at')}
        className={cx(styles['header-cell'], styles.date)}
        data-test-table-header-task-created-at
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'created_at' && styles.active)}
          onClick={() => {
            handleSort('created_at');
          }}
          type="button"
        >
          <FormattedMessage id="request.table.cell.date.created-on" />
          <SortIcon isActive={sortField === 'created_at'} isAscending={isAscending} />
        </button>
      </th>

      <th
        aria-sort={getAriaSort('status')}
        className={cx(styles['header-cell'], styles.status)}
        data-test-table-header-task-status
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'status' && styles.active)}
          onClick={() => {
            handleSort('status');
          }}
          type="button"
        >
          <FormattedMessage id="tasks.headers.status" />
          <SortIcon isActive={sortField === 'status'} isAscending={isAscending} />
        </button>
      </th>

      <th
        className={cx(styles['header-cell'], styles['align-right'], styles.amount)}
        data-test-table-header-task-amount
        scope="col"
      >
        <div className={styles['header-content']}>
          <FormattedMessage id="tasks.headers.amount-limit" />
        </div>
      </th>

      <th aria-hidden="true" className={cx(styles['header-cell'], styles.empty)} scope="col" />
    </>
  );
}
