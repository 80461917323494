import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { type Registry as Services } from '@ember/service';
import { useQueryClient } from '@tanstack/react-query';
import { type MemorizeCategoriesRule } from '../models/memorize-category';
import { getLatestTransactionQueryKey } from '../utils/transactions/get-latest-transaction-query-key';
import type { SearchTransactionsMeta, Transaction } from '../graphql';
import type { CashflowCategoryAssignment } from '../models/cash-flow-category';
import { useOrganizationManager } from './use-organization-manager';

export const useCategorizationRulesManager = (): Services['categorization-rules-manager'] => {
  return useEmberService('categorization-rules-manager');
};

interface CachedTransactions {
  meta: SearchTransactionsMeta;
  transactions: Transaction[];
}

export function useReactCategorizationRulesManager(): {
  applyRules: (rules: MemorizeCategoriesRule[], storeTransactionIds: string[]) => void;
  categorizeTransactions: (assignment: CashflowCategoryAssignment) => void;
} {
  const queryClient = useQueryClient();
  const organizationManager = useOrganizationManager();
  const { id: organizationId } = organizationManager.organization;

  const applyRules = (rules: MemorizeCategoriesRule[], storeTransactionIds: string[]): void => {
    if (!rules.length) return;

    const latestTransactionQueryKey = getLatestTransactionQueryKey(queryClient, organizationId);
    if (!latestTransactionQueryKey) return;

    const cachedTransactions: CachedTransactions | undefined =
      queryClient.getQueryData(latestTransactionQueryKey);
    if (!cachedTransactions) return;

    const applicableCategoryId = rules[0]?.categoryId;
    if (!applicableCategoryId) return;

    const updatedTransactions = [...cachedTransactions.transactions].map(transaction => {
      const updatableTransaction = { ...transaction };
      if (storeTransactionIds.includes(updatableTransaction.id)) {
        updatableTransaction.categoryAssignment = {
          id: applicableCategoryId,
          source: 'manual',
        };
      }
      return updatableTransaction;
    });

    const updatedCache: CachedTransactions = {
      meta: cachedTransactions.meta,
      transactions: updatedTransactions,
    };

    queryClient.setQueryData(latestTransactionQueryKey, updatedCache);
  };

  const categorizeTransactions = ({
    transactionIds,
    categoryId,
  }: CashflowCategoryAssignment): void => {
    const latestTransactionQueryKey = getLatestTransactionQueryKey(queryClient, organizationId);
    if (!latestTransactionQueryKey) return;

    const cachedTransactions: CachedTransactions | undefined =
      queryClient.getQueryData(latestTransactionQueryKey);

    if (!cachedTransactions) return;

    const updatedTransactions = [...cachedTransactions.transactions].map(transaction => {
      const updatableTransaction = { ...transaction };
      if (transactionIds.includes(updatableTransaction.id)) {
        updatableTransaction.categoryAssignment = {
          // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style -- contradicts no-non-null-assertion
          id: categoryId as string,
          source: 'manual',
        };
      }
      return updatableTransaction;
    });

    const updatedCache: CachedTransactions = {
      meta: cachedTransactions.meta,
      transactions: updatedTransactions,
    };
    queryClient.setQueryData(latestTransactionQueryKey, updatedCache);
  };

  return { applyRules, categorizeTransactions };
}
