import { useMutation } from '@tanstack/react-query';
import type { UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { transactionNamespace } from 'qonto/constants/hosts';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';
import type { TransactionModel } from 'qonto/react/models/transaction';

interface MutateAttachmentArgs {
  transactionId: string;
  attachmentId: string;
}

export const useLinkTransactionAttachment = (
  options?: UseMutationOptions<TransactionModel, Response, MutateAttachmentArgs>
): UseMutationResult<TransactionModel, Response, MutateAttachmentArgs> => {
  const fetchApi = useFetchApi();

  const linkTransactionAttachment = async ({
    transactionId,
    attachmentId,
  }: MutateAttachmentArgs): Promise<TransactionModel> => {
    const response = await fetchApi(
      `${transactionNamespace}/transactions/${transactionId}/link_attachments`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          transaction: {
            attachment_ids: [attachmentId],
          },
        }),
      }
    );

    if (!response.ok) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal -- required for handling the error where the hook is used
      throw response;
    }

    const transaction = (await response.json()) as TransactionModel;
    return transaction;
  };

  return useMutation({
    throwOnError: true,
    ...options,
    mutationFn: linkTransactionAttachment,
  });
};
