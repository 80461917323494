import React from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { FlowValueProposition } from 'qonto/react/components/flows/screens';
import styles from './value-proposition.strict-module.css';

interface ValuePropositionProps {
  hasFreeTrial?: boolean;
  onClose: () => void;
  onCtaClick: () => void;
}

export function ValueProposition({
  hasFreeTrial,
  onClose,
  onCtaClick,
}: ValuePropositionProps): React.ReactNode {
  const { formatMessage } = useIntl();

  const items = [
    {
      id: 'automated-invoicing',
      title: formatMessage({
        id: 'receivable-invoices.ar-addon-value-proposition-modal.benefits.automated-invoicing.title',
      }),
      description: formatMessage({
        id: 'receivable-invoices.ar-addon-value-proposition-modal.benefits.automated-invoicing.description',
      }),
    },
    {
      id: 'document-titles',
      title: formatMessage({
        id: 'receivable-invoices.ar-addon-value-proposition-modal.benefits.document-titles.title',
      }),
      description: formatMessage({
        id: 'receivable-invoices.ar-addon-value-proposition-modal.benefits.document-titles.description',
      }),
    },
    {
      id: 'centralized-finances',
      title: formatMessage({
        id: 'receivable-invoices.ar-addon-value-proposition-modal.benefits.centralized-finances.title',
      }),
      description: formatMessage({
        id: 'receivable-invoices.ar-addon-value-proposition-modal.benefits.centralized-finances.description',
      }),
    },
  ];

  return (
    <FlowValueProposition
      className={styles.container}
      illustration={
        <LottiePlayer
          className={styles.lottie}
          path="/lotties/receivable-invoices/section-and-attachments-vp.json"
        />
      }
      onClose={onClose}
    >
      <div className={styles.contentContainer}>
        <div>
          <h2 className="title-2 mb-8" data-test-value-proposition-title>
            {formatMessage({ id: 'receivable-invoices.ar-addon-value-proposition-modal.title' })}
          </h2>
          <p className="body-2" data-test-value-proposition-subtitle>
            {hasFreeTrial
              ? formatMessage({
                  id: 'receivable-invoices.ar-addon-value-proposition-modal.free-trial-available.title',
                })
              : formatMessage({
                  id: 'receivable-invoices.ar-addon-value-proposition-modal.free-trial-not-available.title',
                })}
          </p>
        </div>
        <ul className={styles.list} data-test-value-proposition-list>
          {items.map(item => (
            <li className={styles.listItem} key={item.id}>
              <h4 className={cx('title-4', 'mb-8')}>{item.title}</h4>
              <p className={cx('body-2', styles.itemDescription)}>{item.description}</p>
            </li>
          ))}
        </ul>
        <Button data-test-value-proposition-cta onPress={onCtaClick}>
          {hasFreeTrial
            ? formatMessage({ id: 'promotion-system.cta.trial' })
            : formatMessage({ id: 'upsell.add-ons.cta' })}
        </Button>
      </div>
    </FlowValueProposition>
  );
}
