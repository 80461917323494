import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from '@tanstack/react-query';
import { cashFlowCategoriesNamespaceV2 } from 'qonto/constants/hosts';
import type { CashflowForecastEntryUpdatable } from '../api/models/cash-flow-forecast-entry';
import { useFetchApi, type FetchFunction } from './use-fetch-api';

export interface PutCashFlowForecastEntriesRequestPayload {
  forecast_entries: CashflowForecastEntryUpdatable[];
  remove_associated_formulas?: boolean;
}

export const updateCashFlowForecast = async (
  fetchApi: FetchFunction,
  requestBody: PutCashFlowForecastEntriesRequestPayload
): Promise<void> => {
  const response = await fetchApi(
    `${cashFlowCategoriesNamespaceV2}/cash-flow/timeseries/forecasts/entries`,
    {
      method: 'PUT',
      body: JSON.stringify(requestBody),
    }
  );

  if (!response.ok) {
    throw new Error(`Could not update forecast entries: ${response.statusText}`);
  }
};

export const useUpdateCashFlowForecast = (
  options?: UseMutationOptions<void, Error, PutCashFlowForecastEntriesRequestPayload>
): UseMutationResult<void, Error, PutCashFlowForecastEntriesRequestPayload> => {
  const fetchApi = useFetchApi();

  return useMutation({
    mutationFn: request => updateCashFlowForecast(fetchApi, request),
    ...options,
  });
};
