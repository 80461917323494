import { type ReactNode } from 'react';
import { useClaim } from 'qonto/react/hooks/use-claim';
import { Atm } from './atm';
import styles from './claim.strict-module.css';
import { Commercial } from './commercial';
import { Disputed } from './disputed';
import { Fail } from './fail';
import { Fraud } from './fraud';
import { Outdated } from './outdated';
import { PendingClaim } from './pending-claim';
import { Processing } from './processing';
import type { ClaimProps } from './types';

function ClaimContainer({ children }: { children: ReactNode }): ReactNode {
  return (
    <div
      className={styles.container}
      data-test-help-center-selected-item="claim"
      data-test-help-center-selected-item-claim
    >
      {children}
    </div>
  );
}

export function Claim({ selectedClaimType, highlightedItem, onClose }: ClaimProps): ReactNode {
  const { claimType, getErrorText, getValidityPeriodText, handleDisputeStart } = useClaim({
    selectedClaimType,
    highlightedItem,
    onClose,
  });

  if (claimType.isPending) {
    return (
      <ClaimContainer>
        <PendingClaim />
      </ClaimContainer>
    );
  }

  if (claimType.isDisputed) {
    return (
      <ClaimContainer>
        <Disputed />
      </ClaimContainer>
    );
  }

  if (claimType.isProcessing) {
    return (
      <ClaimContainer>
        <Processing />
      </ClaimContainer>
    );
  }

  if (claimType.isOutdated) {
    return (
      <ClaimContainer>
        <Outdated validityPeriodText={getValidityPeriodText()} />
      </ClaimContainer>
    );
  }

  if (claimType.isFailing) {
    return (
      <ClaimContainer>
        <Fail errorText={getErrorText()} />
      </ClaimContainer>
    );
  }

  if (claimType.isAtmClaim) {
    return (
      <ClaimContainer>
        <Atm onClick={handleDisputeStart} />
      </ClaimContainer>
    );
  }

  if (claimType.isCommercialClaim) {
    return (
      <ClaimContainer>
        <Commercial onClick={handleDisputeStart} />
      </ClaimContainer>
    );
  }

  if (claimType.isFraudClaim) {
    return (
      <ClaimContainer>
        <Fraud onClick={handleDisputeStart} />
      </ClaimContainer>
    );
  }

  return null;
}
