import * as React from 'react';
import { useIntl } from 'react-intl';
import { CheckoutHeader } from '@repo/domain-kit/pricing';
import styles from './confirm-header.strict-module.css';

export interface ProductInfo {
  price: React.ReactNode;
  priceNote?: React.ReactNode;
  subtitle: React.ReactNode;
  title: string;
}

interface ConfirmHeaderProps {
  addon?: ProductInfo;
  pricePlan?: ProductInfo;
}

export function ConfirmHeader({ addon, pricePlan }: ConfirmHeaderProps): React.ReactNode {
  const { formatMessage } = useIntl();

  if (!pricePlan && !addon) {
    return null;
  }

  return (
    <div className={styles.container}>
      {pricePlan ? (
        <div className={styles.header} data-test-plan-checkout-header>
          <div className="title-3" data-test-confirm-header-title>
            {formatMessage({ id: 'subscription.change.confirmation.plan.section.title' })}
          </div>
          <CheckoutHeader
            elevation="high"
            price={pricePlan.price}
            priceNote={pricePlan.priceNote}
            subtitle={pricePlan.subtitle}
            title={pricePlan.title}
            withBorder
          />
        </div>
      ) : null}
      {addon ? (
        <div className={styles.header} data-test-addon-checkout-header>
          <div className="title-3" data-test-confirm-header-title>
            {formatMessage({ id: 'subscription.change.confirmation.addon.section.title' })}
          </div>
          <CheckoutHeader
            elevation="high"
            price={addon.price}
            priceNote={addon.priceNote}
            subtitle={addon.subtitle}
            title={addon.title}
            withBorder
          />
        </div>
      ) : null}
    </div>
  );
}
