import { type ReactNode } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FullscreenBase, Button } from '@repo/design-system-kit';
import styles from './mileage-calculation-details.strict-module.css';

interface Rate {
  distance_meters: number;
  start_meters: number;
  end_meters?: number;
  value: string;
  unit: string;
}

interface Amount {
  value: string;
  currency: string;
}

interface MileageCalculation {
  rates: Rate[];
  distance_meters: number;
  yearly_distance_meters: number;
  electric_bonus_amount?: Amount;
}

interface MileageCalculationDetailsProps {
  close: () => void;
  data: {
    amount: Amount;
    mileageCalculation: MileageCalculation;
  };
}

export function MileageCalculationDetails({
  close,
  data,
}: MileageCalculationDetailsProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const zendeskLocalization = useEmberService('zendesk-localization');

  const metersToKmsConverter = (distanceInMeters: number): number => {
    return Math.ceil(distanceInMeters / 1000);
  };

  const distanceWithKmUnit = (distance: number): string => {
    return formatMessage(
      { id: 'requests.mileage.steps.request-details.calculation-modal.total-distance' },
      { distance }
    );
  };

  const slabLabel = (rate: Rate): string => {
    if (rate.start_meters === 0) {
      const distance = metersToKmsConverter(rate.end_meters || 0);
      return formatMessage(
        { id: 'requests.mileage.steps.request-details.calculation-modal.scale-tier-1' },
        { distance }
      );
    }

    if (rate.end_meters) {
      const startDistance = metersToKmsConverter(rate.start_meters);
      const endDistance = metersToKmsConverter(rate.end_meters);
      return formatMessage(
        { id: 'requests.mileage.steps.request-details.calculation-modal.scale-tier-2' },
        { startDistance, endDistance }
      );
    }

    const tier3Distance = rate.start_meters - 1;
    const distance = metersToKmsConverter(tier3Distance);
    return formatMessage(
      { id: 'requests.mileage.steps.request-details.calculation-modal.scale-tier-3' },
      { distance }
    );
  };

  const slabRateValue = (slabRate: Rate): string => {
    const distance = metersToKmsConverter(slabRate.distance_meters);
    return formatMessage(
      { id: 'requests.mileage.sidebar.calculation.value' },
      { distance, rate: slabRate.value }
    );
  };

  const handleFaqClick = (): void => {
    segment.track('request_calculation_FAQ_clicked', { origin: 'mileage_request' });
  };

  const yearlyDistanceInKms = distanceWithKmUnit(
    metersToKmsConverter(data.mileageCalculation.yearly_distance_meters)
  );

  const reimbursementDistanceInKms = distanceWithKmUnit(
    metersToKmsConverter(data.mileageCalculation.distance_meters)
  );

  const slabsRates = data.mileageCalculation.rates.map(rate => ({
    label: slabLabel(rate),
    value: slabRateValue(rate),
  }));

  return (
    <FullscreenBase data-test-mileage-calculation-modal onClose={close}>
      <div className={styles['content-wrapper']}>
        <main className={styles.main}>
          <h1 className="title-1 mb-16" data-test-mileage-calculation-modal-title>
            <FormattedMessage id="requests.mileage.steps.request-details.calculation-modal.title" />
          </h1>

          <p className="body-1 mb-32" data-test-mileage-calculation-modal-description>
            <FormattedMessage
              id="requests.mileage.steps.request-details.calculation-modal.subtitle"
              values={{
                learnMore: (
                  <a
                    className="body-link"
                    data-test-faq-link
                    href={zendeskLocalization.getLocalizedArticle(7936264)}
                    onClick={handleFaqClick}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FormattedMessage id="requests.mileage.sidebar.calculation.link-label" />
                  </a>
                ),
              }}
            />
          </p>

          <div>
            <div className="mb-16" data-test-mileage-calculation-modal-yearly-distance>
              <div className={styles['calculation-item']}>
                <p className="body-2">
                  <FormattedMessage id="requests.mileage.steps.request-details.calculation-modal.total-distance-label" />
                </p>
                <p className="body-2" data-test-mileage-calculation-modal-yearly-distance-kms>
                  {yearlyDistanceInKms}
                </p>
              </div>
            </div>

            <div className="mb-16">
              <div className="mb-16" data-test-mileage-calculation-modal-reimbursement-distance>
                <div className={styles['calculation-item']}>
                  <p className="body-1">
                    <FormattedMessage id="requests.mileage.sidebar.calculation.distance" />
                  </p>
                  <p
                    className="body-1"
                    data-test-mileage-calculation-modal-reimbursement-distance-kms
                  >
                    {reimbursementDistanceInKms}
                  </p>
                </div>
              </div>

              <div
                className={styles['calculation-details']}
                data-test-mileage-calculation-modal-slabs-rates
              >
                {slabsRates.map((rate, index) => (
                  // eslint-disable-next-line react/no-array-index-key -- The list is static so we can use index
                  <div className={styles['calculation-item']} key={index}>
                    <p
                      className="body-2"
                      data-test-mileage-calculation-modal-slabs-rates-label={index}
                    >
                      {rate.label}
                    </p>
                    <p
                      className="body-2"
                      data-test-mileage-calculation-modal-slabs-rates-value={index}
                    >
                      {rate.value}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {data.mileageCalculation.electric_bonus_amount ? (
              <div className="mb-16">
                <div className={styles['calculation-item']}>
                  <p className="body-2" data-test-mileage-calculation-modal-electric-bonus-label>
                    <FormattedMessage id="requests.mileage.sidebar.calculation.eletric-bonus" />
                  </p>
                  <p className="body-2" data-test-mileage-calculation-modal-electric-bonus-amount>
                    <FormattedNumber
                      currency={data.mileageCalculation.electric_bonus_amount.currency}
                      style="currency"
                      value={Number(data.mileageCalculation.electric_bonus_amount.value)}
                    />
                  </p>
                </div>
              </div>
            ) : null}

            <div className="mb-32">
              <div className={styles['calculation-item']}>
                <p className="title-3" data-test-mileage-calculation-modal-total-label>
                  <FormattedMessage id="requests.mileage.sidebar.calculation.total" />
                </p>
                <p className="title-3" data-test-mileage-calculation-modal-total-amount>
                  <FormattedNumber
                    currency={data.amount.currency}
                    style="currency"
                    value={Number(data.amount.value)}
                  />
                </p>
              </div>
            </div>
          </div>

          <Button className={styles.btn} data-test-cta-btn onPress={close} variant="primary">
            <FormattedMessage id="btn.got_it" />
          </Button>
        </main>
      </div>
    </FullscreenBase>
  );
}
