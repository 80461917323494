import type { ReactNode } from 'react';
import { IconChevronRightOutlined, SkeletonLoader } from '@repo/design-system-kit';
import styles from './loading.strict-module.css';

function Item(): ReactNode {
  return (
    <div className={styles.item} data-test-help-center-loading-item>
      <SkeletonLoader.Block
        borderRadius="50%"
        data-test-placeholder-block
        height="32px"
        width="32px"
      />
      <SkeletonLoader.Line className={styles.line} data-test-placeholder-line width="160px" />
      <IconChevronRightOutlined
        aria-hidden="true"
        className={styles.icon}
        data-test-placeholder-icon="icon_chevron_right_outlined"
      />
    </div>
  );
}

export function Loading(): ReactNode {
  return (
    <div aria-hidden="true" className={styles.content} data-test-help-center-loading>
      <div className={styles.section} data-test-help-center-loading-section="0">
        <Item />
        <Item />
      </div>
      <div className={styles.section} data-test-help-center-loading-section="1">
        <Item />
        <Item />
        <Item />
      </div>
      <div className={styles.section} data-test-help-center-loading-section="2">
        <Item />
      </div>
    </div>
  );
}
