import { useIntl } from 'react-intl';

interface FormatAmountOptions {
  amount: { currency: string; value: number };
  localAmount?: { currency: string; value: number };
}

export function useFormattedAmount({ amount, localAmount }: FormatAmountOptions): {
  formattedAmount: string;
  formattedLocalAmount: string;
} {
  const { formatNumber } = useIntl();
  const { currency, value } = amount;

  const getFormattedAmount = (amountNumber: number): string => {
    const isNull = amountNumber === 0;
    const isNegative = amountNumber < 0 && !isNull;

    let formattedAmount = formatNumber(amountNumber, {
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'decimal',
    });

    if (isNegative) {
      const NO_BREAKING_SPACE_CHAR = '\xA0';
      formattedAmount = formattedAmount.replace('-', `-${NO_BREAKING_SPACE_CHAR}`);
    }

    return formattedAmount;
  };

  return {
    formattedAmount: getFormattedAmount(value),
    formattedLocalAmount: localAmount ? getFormattedAmount(localAmount.value) : '',
  };
}
