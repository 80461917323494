import { useCallback, useEffect, useRef } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { parseContentDisposition } from 'qonto/utils/parse-content-disposition';

interface UseDownloadWithHeadersProps {
  href: string;
}

export function useDownloadWithHeaders({ href }: UseDownloadWithHeadersProps): {
  downloadFile: () => Promise<void>;
} {
  const networkManager = useEmberService('network-manager');

  const objectUrlRef = useRef<string | null>(null);

  const cleanup = useCallback(() => {
    if (objectUrlRef.current) {
      URL.revokeObjectURL(objectUrlRef.current);
      objectUrlRef.current = null;
    }
  }, []);

  const downloadFile = useCallback(async () => {
    async function fetchPdf(): Promise<Response> {
      const response = await fetch(href, {
        method: 'GET',
        headers: { ...networkManager.requestHeaders },
        credentials: 'include',
      });

      return response;
    }

    const response = await fetchPdf();

    let filename;
    const filenameHeader = parseContentDisposition(response.headers).get('filename');
    if (typeof filenameHeader === 'string') {
      filename = decodeURIComponent(filenameHeader);
    }

    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    objectUrlRef.current = objectUrl;

    // Create temporary link and trigger download
    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = filename || 'document.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Cleanup object URL after download starts
    cleanup();
  }, [cleanup, href, networkManager]);

  // Cleanup on unmount
  useEffect(() => {
    return cleanup;
  }, [cleanup]);
  return { downloadFile };
}

export const downloadHook = {
  useDownloadWithHeaders,
};
