import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './expense-report.strict-module.css';

interface ExpenseReportTableCellProps {
  note: string;
  hasBankAccountFeature: boolean;
}

export function ExpenseReportTableCell({
  note,
  hasBankAccountFeature,
}: ExpenseReportTableCellProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <div className={styles['cell-content']} data-test-request-cell-expense-report>
      <StaticThemedAsset
        alt=""
        aria-hidden="true"
        assetPath="/illustrations/request-expense-report.svg"
        className={styles['expense-report-icon']}
        data-test-request-cell-expense-report-icon
      />
      <div className={styles['cell-info']}>
        <p className={styles.title} data-test-request-cell-expense-report-title>
          {formatMessage({
            id: hasBankAccountFeature ? 'labels.reimbursement' : 'labels.expense-reports',
          })}
        </p>
        <p
          className={cx('caption', styles.subtitle)}
          data-test-request-cell-expense-report-subtitle
        >
          {note}
        </p>
      </div>
    </div>
  );
}
