import { useQuery } from '@tanstack/react-query';
import type { QueryObserverOptions, UseQueryResult } from '@tanstack/react-query';
import { transactionNamespace } from 'qonto/constants/hosts';
import type { Transaction } from 'qonto/react/models/transaction';
import type { SearchTransactionsMeta } from 'qonto/react/graphql';
import { camelizeKeys } from '../api/utils/camelize-keys';
import { useFetchApi } from './use-fetch-api';

export interface Expression {
  property: 'status' | 'operation_method' | 'amount' | 'attachment_ids' | 'settled_at';
  values: (string | undefined)[];
  operator: 'in' | 'not_in' | 'lt' | 'within' | 'interval';
}

export interface TransactionsQuery {
  includes: string[];
  filter_group: {
    conditional: string;
    expressions: Expression[];
  };
  sort: { property: string; direction: string };
  pagination: { page: number; per_page: number };
  search: string;
  organization_id: string;
}

interface TransactionSearchResult {
  meta: Omit<SearchTransactionsMeta, '__typename'>;
  transactions: Omit<Transaction, 'localizedOperationMethod'>[];
}

export function useFetchTransactions(
  query: TransactionsQuery,
  queryOptions?: Partial<QueryObserverOptions<TransactionSearchResult>>
): UseQueryResult<TransactionSearchResult> {
  const fetchApi = useFetchApi();

  return useQuery({
    ...queryOptions,
    queryKey: ['transactions', query],
    queryFn: async () => {
      const response = await fetchApi(`${transactionNamespace}/transactions/search`, {
        method: 'POST',
        body: JSON.stringify(query),
      });

      if (!response.ok) {
        throw new Error(`[HTTP status: ${response.status}] Failed to fetch transactions`);
      }

      const searchResult = (await response.json()) as TransactionSearchResult;

      return camelizeKeys(searchResult);
    },
  });
}
