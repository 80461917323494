import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import {
  useEmberIntl,
  useEmberRouter,
  useNavigate,
} from '@qonto/react-migration-toolkit/react/hooks/providers';
import {
  type LDFlagSet,
  LDProvider,
  PolymorphicRouterContextProvider,
  PolymorphicNavigateProvider,
} from '@qonto/react-migration-toolkit/react/providers';
import { PolymorphicSegmentProvider } from '@repo/poly-hooks/segment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { PropsWithChildren, ReactNode } from 'react';
import { I18nProvider } from 'react-aria';
import { RawIntlProvider } from 'react-intl';
import { ToastProvider } from '@repo/design-system-kit';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export const queryClient = new QueryClient();
interface CustomProvidersProps extends PropsWithChildren {
  ldFlags: LDFlagSet;
}

export function CustomProviders({ children, ldFlags }: CustomProvidersProps): ReactNode {
  const intl = useEmberIntl();
  const router = useEmberRouter();
  const segment = useEmberService('segment');
  const navigate = useNavigate();

  // Since we only pass a 2-char locale react aria default to en-US
  // making the date format mm/dd/yyyy
  const reactAriaIntlLocale = intl.locale === 'en' ? 'en-GB' : intl.locale;

  const hasQueryDevtools = safeLocalStorage.getItem('QUERY_DEV_TOOLS') === 'true';

  return (
    <PolymorphicNavigateProvider navigate={navigate}>
      <PolymorphicSegmentProvider segment={segment}>
        <LDProvider ldFlags={ldFlags}>
          <PolymorphicRouterContextProvider router={router}>
            <RawIntlProvider value={intl}>
              <I18nProvider locale={reactAriaIntlLocale}>
                <ToastProvider>
                  <QueryClientProvider client={queryClient}>
                    {children}
                    {hasQueryDevtools ? <ReactQueryDevtools buttonPosition="bottom-left" /> : null}
                  </QueryClientProvider>
                </ToastProvider>
              </I18nProvider>
            </RawIntlProvider>
          </PolymorphicRouterContextProvider>
        </LDProvider>
      </PolymorphicSegmentProvider>
    </PolymorphicNavigateProvider>
  );
}
