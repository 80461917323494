import { type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import type { Locale } from '@repo/kyc';
import { MarketingButton } from 'qonto/react/components/freemium-upgrade/marketing-button';
import { MarketingCard } from 'qonto/react/components/freemium-upgrade/marketing-card';
import { MarketingFeaturesTable } from 'qonto/react/components/freemium-upgrade/marketing-features-table';
import styles from './freemium-upgrade.strict-module.css';

const availableLottiesLanguages: Locale[] = ['en', 'fr'];

export function FreemiumUpgrade(): ReactNode {
  const intl = useIntl();
  const zendeskLocalization = useEmberService('zendesk-localization');
  const upsellManager = useEmberService('upsell-manager');

  const lottieLocale: Locale = availableLottiesLanguages.includes(intl.locale) ? intl.locale : 'en';

  const handleMarketingButtonClick = (): void => {
    upsellManager.upsell();
  };

  return (
    <main className={styles.freemiumUpgrade}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1 className="title-1 mb-16" data-test-freemium-upgrade-title>
            <FormattedMessage id="qonto-invoicing.upsell-page.level1.title" />
          </h1>
          <h2 className="title-4 mb-32" data-test-freemium-upgrade-subtitle>
            <FormattedMessage id="qonto-invoicing.upsell-page.level1.subtitle" />
          </h2>

          <h1 className="title-1 mb-32">
            <FormattedMessage id="qonto-invoicing.upsell-page.level2.title" />
          </h1>

          <div className={styles.marketingCards} data-test-marketing-cards-section>
            <MarketingCard
              lottiePath={`/lotties/freemium-upgrade/${lottieLocale}/cash-flow-management.json`}
              subtitle={intl.formatMessage({
                id: 'qonto-invoicing.upsell-page.level2.secondary-feature1.subtitle',
              })}
              title={intl.formatMessage({
                id: 'qonto-invoicing.upsell-page.level2.secondary-feature1.title',
              })}
            />

            <MarketingCard
              lottiePath={`/lotties/freemium-upgrade/${lottieLocale}/more-integrations.json`}
              subtitle={intl.formatMessage({
                id: 'qonto-invoicing.upsell-page.level2.secondary-feature2.subtitle',
              })}
              title={intl.formatMessage({
                id: 'qonto-invoicing.upsell-page.level2.secondary-feature2.title',
              })}
              type="secondary"
            />

            <MarketingCard
              lottiePath={`/lotties/freemium-upgrade/${lottieLocale}/financing-capabilities.json`}
              subtitle={
                <FormattedMessage
                  id="qonto-invoicing.upsell-page.level2.secondary-feature3.subtitle"
                  values={{
                    learnMoreLink: (
                      <>
                        <br />
                        <a
                          className="body-link"
                          data-test-freemium-upgrade-feature
                          href={zendeskLocalization.getLocalizedArticle(9015327)}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <FormattedMessage id="qonto-invoicing.upsell-page.level2.secondary-feature3.faqLink" />
                        </a>
                      </>
                    ),
                  }}
                />
              }
              title={intl.formatMessage({
                id: 'qonto-invoicing.upsell-page.level2.secondary-feature3.title',
              })}
              type="secondary"
            />
          </div>

          <div className={styles.upgradeFeatures}>
            <div className={styles.upgradeFeaturesText}>
              <h1 className="title-1 mb-16">
                <FormattedMessage id="qonto-invoicing.upsell-page.level3.title" />
              </h1>
              <h2 className="title-4">
                <FormattedMessage id="qonto-invoicing.upsell-page.level3.subtitle" />
              </h2>
            </div>
            <MarketingFeaturesTable />
          </div>
        </div>
      </div>
      <div className={styles.marketingButtonWrapper}>
        <MarketingButton onPress={handleMarketingButtonClick} />
      </div>
    </main>
  );
}
