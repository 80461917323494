import type { ReactNode, ErrorInfo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import type { ErrorBoundaryProps } from 'react-error-boundary';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';

export function QontoErrorBoundary(props: ErrorBoundaryProps): ReactNode {
  const sentry = useEmberService('sentry');

  const onError = (error: Error, info: ErrorInfo): void => {
    sentry.captureException(error);
    props.onError?.(error, info);
  };

  return <ErrorBoundary {...props} onError={onError} />;
}
