import type { QUnitType } from 'dayjs';

export enum CashflowPeriodInterval {
  CurrentMonth = 'current_month',
  CurrentQuarter = 'current_quarter',
  CurrentYear = 'current_year',
  Last30days = 'last_30_days',
  Last3months = 'last_3_months',
  Last12months = 'last_12_months',
}

export enum CashflowPeriodKey {
  CurrentMonth = 'month_to_date',
  CurrentQuarter = 'quarter_to_date',
  CurrentYear = 'year_to_date',
  Last30days = 'last_30_days',
  Last3months = 'last_3_months',
  Last12months = 'last_12_months',
  CustomPeriod = 'custom_period',
}

export enum CashflowPeriodRate {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Yearly = 'yearly',
}

export enum CashflowSidePanelTabs {
  Actual = 'actual',
  Forecast = 'forecast',
}

// JS constant translation
export const PERIOD_RATES = {
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly',
};

export const CashflowFrequencyToInterval: Record<CashflowPeriodRate, QUnitType> = {
  [CashflowPeriodRate.Monthly]: 'month',
  [CashflowPeriodRate.Quarterly]: 'quarter',
  [CashflowPeriodRate.Yearly]: 'year',
};

export interface CashflowPeriod {
  interval: CashflowPeriodInterval;
  key: CashflowPeriodKey;
  label: string;
  rate?: CashflowPeriodRate;
  startDate?: string;
  endDate?: string;
}
