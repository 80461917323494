/* eslint-disable camelcase -- API needs snake_case */
import { useQueryClient } from '@tanstack/react-query';
import type {
  CashflowParentCategory,
  CashflowCategories,
  CashflowCategory,
  CashflowCategorySide,
} from '../models/cash-flow-category';
import { colorsMap } from '../utils/cashflow-categories/categories-data-map';
import { useOrganizationManager } from './use-organization-manager';
import { QUERY_KEYS } from './use-fetch-cashflow-categories';
import type {
  PatchCashFlowCategoryRequest,
  PatchCashFlowSubcategoryRequest,
} from './use-update-cash-flow-category';
import type { CreateCashFlowCategoryRequest } from './use-create-cash-flow-category';
import type { CreateCashFlowSubcategoryRequest } from './use-create-cash-flow-subcategory';

export const useCashflowCategories = (): {
  getCategories: () => CashflowCategories | undefined;
  getInflowCategories: () => CashflowParentCategory[] | undefined;
  getOutflowCategories: () => CashflowParentCategory[] | undefined;
  getCategoryById: (categoryId: string) => CashflowParentCategory | undefined;
  cancelCategoriesQueries: () => Promise<void>;
  invalidateCategoriesQuery: () => Promise<void>;
  removeCategoryById: (
    categoryId: string,
    type: CashflowCategorySide
  ) => {
    allCategoriesBeforeMutation: CashflowCategories;
  };
  removeSubcategoryById: (
    categoryId: string,
    subcategoryId: string,
    type: CashflowCategorySide
  ) => {
    allCategoriesBeforeMutation: CashflowCategories;
  };
  setAllCategories: (categories: CashflowCategories) => void;
  setNewCategory: (payload: CreateCashFlowCategoryRequest['payload']) => {
    allCategoriesBeforeMutation: CashflowCategories;
  };
  setCategory: (
    type: CashflowCategorySide,
    categoryId: string,
    payload: PatchCashFlowCategoryRequest['payload']
  ) => {
    allCategoriesBeforeMutation: CashflowCategories;
    hasTypeChanged: boolean;
  };
  setNewSubcategory: (
    categoryId: string,
    type: CashflowCategorySide,
    payload: CreateCashFlowSubcategoryRequest['payload']
  ) => {
    allCategoriesBeforeMutation: CashflowCategories;
  };
  setSubcategory: (
    categoryId: string,
    subcategoryId: string,
    type: CashflowCategorySide,
    payload: PatchCashFlowSubcategoryRequest['payload']
  ) => {
    allCategoriesBeforeMutation: CashflowCategories;
  };
  reorderCategoriesByType: (
    type: CashflowCategorySide,
    updatedCategories: CashflowParentCategory[]
  ) => {
    allCategoriesBeforeMutation: CashflowCategories;
  };
  reorderSubcategories: (
    categoryId: string,
    type: CashflowCategorySide,
    updatedSubcategories: CashflowCategory[]
  ) => {
    allCategoriesBeforeMutation: CashflowCategories;
  };
} => {
  const { organization } = useOrganizationManager();
  const queryClient = useQueryClient();

  const getCategories = (): CashflowCategories | undefined =>
    queryClient.getQueryData<CashflowCategories>(QUERY_KEYS.all(organization.id));

  const getInflowCategories = (): CashflowParentCategory[] | undefined =>
    queryClient.getQueryData<CashflowParentCategory[]>(QUERY_KEYS.inflow(organization.id));

  const getOutflowCategories = (): CashflowParentCategory[] | undefined =>
    queryClient.getQueryData<CashflowParentCategory[]>(QUERY_KEYS.outflow(organization.id));

  const getCategoryById = (categoryId: string): CashflowParentCategory | undefined => {
    return queryClient.getQueryData<CashflowParentCategory>(
      QUERY_KEYS.byId(organization.id, categoryId)
    );
  };

  const cancelCategoriesQueries = async (): Promise<void> => {
    await queryClient.cancelQueries({ queryKey: QUERY_KEYS.all(organization.id) });
  };

  const invalidateCategoriesQuery = async (): Promise<void> => {
    await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.all(organization.id) });
  };

  const removeCategoryById = (
    categoryId: string,
    type: CashflowCategorySide
  ): { allCategoriesBeforeMutation: CashflowCategories } => {
    const allCategories = getCategories();
    if (!allCategories) throw Error('No categories found');

    const categoriesByType = [...allCategories[type]];
    const updatedCategoriesByType = categoriesByType.filter(category => category.id !== categoryId);

    queryClient.setQueryData(QUERY_KEYS.all(organization.id), (old: CashflowCategories) => ({
      ...old,
      [type]: updatedCategoriesByType,
    }));

    return { allCategoriesBeforeMutation: allCategories };
  };

  const removeSubcategoryById = (
    categoryId: string,
    subcategoryId: string,
    type: CashflowCategorySide
  ): {
    allCategoriesBeforeMutation: CashflowCategories;
  } => {
    const allCategories = getCategories();
    if (!allCategories) throw Error('No categories found');

    const categoryBeforeMutation = getCategoryById(categoryId);
    if (!categoryBeforeMutation) throw Error('Category not found');

    const updatedSubcategories = categoryBeforeMutation.subcategories?.filter(
      subcat => subcat.id !== subcategoryId
    );

    const updatedCategory = {
      ...categoryBeforeMutation,
      subcategories: updatedSubcategories,
    };

    const updatedCategoriesByType = [...allCategories[type]];
    const updatedCategoryIndex = updatedCategoriesByType.findIndex(
      category => category.id === categoryId
    );
    updatedCategoriesByType[updatedCategoryIndex] = updatedCategory;

    queryClient.setQueryData(QUERY_KEYS.all(organization.id), (old: CashflowCategories) => ({
      ...old,
      [type]: updatedCategoriesByType,
    }));

    return {
      allCategoriesBeforeMutation: allCategories,
    };
  };

  const setAllCategories = (categories: CashflowCategories): void => {
    queryClient.setQueryData(QUERY_KEYS.all(organization.id), categories);
  };

  const setNewCategory = (
    payload: CreateCashFlowCategoryRequest['payload']
  ): {
    allCategoriesBeforeMutation: CashflowCategories;
  } => {
    const allCategories = getCategories();
    if (!allCategories) throw Error('No categories found');

    const newCategory = {
      id: 'temp-id',
      colorKey: payload.color_key,
      iconKey: payload.icon_key,
      name: payload.name,
      nameKey: null,
      vatRate: payload.vat_rate ? Number(payload.vat_rate) : null,
    };

    queryClient.setQueryData(QUERY_KEYS.all(organization.id), (old: CashflowCategories) => ({
      ...old,
      [payload.type]: [...old[payload.type], newCategory],
    }));

    return {
      allCategoriesBeforeMutation: allCategories,
    };
  };

  const setCategory = (
    type: CashflowCategorySide,
    categoryId: string,
    payload: PatchCashFlowCategoryRequest['payload']
  ): {
    allCategoriesBeforeMutation: CashflowCategories;
    hasTypeChanged: boolean;
  } => {
    const category = getCategoryById(categoryId);
    const allCategories = getCategories();
    if (!allCategories || !category) throw Error('No categories found');

    const { name, type: updatedType, icon_key, color_key, vat_rate } = payload;

    const hasTypeChanged = updatedType !== type;
    const categoryIndex = allCategories[type].findIndex(cat => cat.id === categoryId);
    const categoriesByType = [...allCategories[type]];

    const updatedCategory = {
      ...categoriesByType[categoryIndex],
      name,
      type: updatedType,
      iconKey: icon_key,
      colorKey: colorsMap[color_key ?? 'default'],
      vatRate: vat_rate?.value ? Number(vat_rate.value) : null,
    };

    updatedCategory.subcategories?.forEach(subcat => {
      subcat.colorKey = updatedCategory.colorKey ?? '';
    });

    queryClient.setQueryData(QUERY_KEYS.all(organization.id), (old: CashflowCategories) => {
      if (!updatedType) throw Error('No updated type found');

      if (hasTypeChanged) {
        const filteredOldType = old[type].filter(cat => cat.id !== categoryId);
        const newTypeCategories = [...old[updatedType], updatedCategory];

        return {
          ...old,
          [type]: filteredOldType,
          [updatedType]: newTypeCategories,
        };
      }

      const updatedCategories = old[type].map(cat =>
        cat.id === categoryId ? updatedCategory : cat
      );

      return {
        ...old,
        [type]: updatedCategories,
      };
    });

    return {
      allCategoriesBeforeMutation: allCategories,
      hasTypeChanged,
    };
  };

  const reorderCategoriesByType = (
    type: CashflowCategorySide,
    updatedCategories: CashflowParentCategory[]
  ): {
    allCategoriesBeforeMutation: CashflowCategories;
  } => {
    const allCategories = getCategories();
    if (!allCategories) throw Error('No categories found');

    queryClient.setQueryData(QUERY_KEYS.all(organization.id), (old: CashflowCategories) => ({
      ...old,
      [type]: updatedCategories,
    }));

    return {
      allCategoriesBeforeMutation: allCategories,
    };
  };

  const setNewSubcategory = (
    categoryId: string,
    type: CashflowCategorySide,
    payload: CreateCashFlowSubcategoryRequest['payload']
  ): {
    allCategoriesBeforeMutation: CashflowCategories;
  } => {
    let allCategories = getCategories();
    if (!allCategories) {
      allCategories = { inflow: [], outflow: [] };
    }

    const updatedCategoriesByType = [...allCategories[type]];
    const categoryIndex = updatedCategoriesByType.findIndex(category => category.id === categoryId);
    const targetCategory = { ...updatedCategoriesByType[categoryIndex] } as CashflowParentCategory;

    const newSubcategory = {
      id: 'temp-id',
      colorKey: targetCategory.colorKey,
      iconKey: targetCategory.iconKey,
      name: payload.name,
      nameKey: null,
      side: type,
      vatRate: payload.vat_rate ? Number(payload.vat_rate) : null,
      parentCategoryId: targetCategory.id,
      parentCategoryVatRate: targetCategory.vatRate ? Number(targetCategory.vatRate) : null,
    };

    const updatedSubcategories = targetCategory.subcategories
      ? [...targetCategory.subcategories, newSubcategory]
      : [newSubcategory];

    const updatedCategory = {
      ...targetCategory,
      subcategories: [...updatedSubcategories],
    };
    updatedCategoriesByType[categoryIndex] = updatedCategory;

    queryClient.setQueryData(QUERY_KEYS.all(organization.id), (old: CashflowCategories) => ({
      ...old,
      [type]: updatedCategoriesByType,
    }));

    return {
      allCategoriesBeforeMutation: allCategories,
    };
  };

  const setSubcategory = (
    categoryId: string,
    subcategoryId: string,
    type: CashflowCategorySide,
    payload: PatchCashFlowSubcategoryRequest['payload']
  ): {
    allCategoriesBeforeMutation: CashflowCategories;
  } => {
    const allCategories = getCategories();
    if (!allCategories) throw Error('No categories found');

    const categoryBeforeMutation = getCategoryById(categoryId);
    if (!categoryBeforeMutation) throw Error('No category found');
    if (!categoryBeforeMutation.subcategories) throw Error('Category has no subcategories');

    const updatedCategoriesByType = [...allCategories[type]];
    const categoryIndex = updatedCategoriesByType.findIndex(category => category.id === categoryId);
    const subcategoryIndex = categoryBeforeMutation.subcategories.findIndex(
      sub => sub.id === subcategoryId
    );

    const targetCategory = updatedCategoriesByType[categoryIndex];
    const targetSubcategory = targetCategory?.subcategories?.[subcategoryIndex];

    if (!targetCategory?.subcategories || !targetSubcategory)
      throw Error('Invalid category selection');

    const updatedCategory = {
      ...targetCategory,
      subcategories: targetCategory.subcategories.map((sub, index) => {
        const { name, vat_rate } = payload;
        return index === subcategoryIndex
          ? {
              ...targetSubcategory,
              name: name ?? sub.name,
              vatRate: vat_rate?.value ? Number(vat_rate.value) : null,
            }
          : sub;
      }),
    };
    updatedCategoriesByType[categoryIndex] = updatedCategory;

    queryClient.setQueryData(QUERY_KEYS.all(organization.id), (old: CashflowCategories) => ({
      ...old,
      [type]: updatedCategoriesByType,
    }));

    return {
      allCategoriesBeforeMutation: allCategories,
    };
  };

  const reorderSubcategories = (
    categoryId: string,
    type: CashflowCategorySide,
    updatedSubcategories: CashflowCategory[]
  ): {
    allCategoriesBeforeMutation: CashflowCategories;
  } => {
    const allCategories = getCategories();
    if (!allCategories) throw Error('No categories found');

    const categoryBeforeMutation = getCategoryById(categoryId);
    if (!categoryBeforeMutation) throw Error('No category found');

    const updatedCategory = {
      ...categoryBeforeMutation,
      subcategories: updatedSubcategories,
    };
    const updatedCategoriesByType = [...allCategories[type]];
    const updatedCategoryIndex = updatedCategoriesByType.findIndex(
      category => category.id === categoryId
    );
    updatedCategoriesByType[updatedCategoryIndex] = updatedCategory;

    queryClient.setQueryData(QUERY_KEYS.all(organization.id), (old: CashflowCategories) => ({
      ...old,
      [type]: updatedCategoriesByType,
    }));

    return {
      allCategoriesBeforeMutation: allCategories,
    };
  };

  return {
    getCategories,
    getInflowCategories,
    getOutflowCategories,
    getCategoryById,
    cancelCategoriesQueries,
    invalidateCategoriesQuery,
    setAllCategories,
    setNewCategory,
    setCategory,
    setNewSubcategory,
    setSubcategory,
    removeCategoryById,
    removeSubcategoryById,
    reorderCategoriesByType,
    reorderSubcategories,
  };
};
