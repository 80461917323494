import type { ReactNode } from 'react';
import { Basket, type BasketProps, CheckoutForm } from '@repo/domain-kit/payment-links';
import { BrowserPreview, Footer, type PaymentMethods } from '@repo/domain-kit/shared';
import styles from './styles.strict-module.css';

export type PreviewProps = Pick<BasketProps, 'items' | 'amount'> & {
  methods: PaymentMethods[];
  organizationName: string;
};

export function Preview({
  amount,
  organizationName,
  methods = [],
  items = [],
}: PreviewProps): ReactNode {
  return (
    <BrowserPreview address="pay.qonto.com" scale={0.52}>
      <div className={styles['preview-content']}>
        <div>
          <Basket amount={amount} isPreview items={items} payee={organizationName} />
        </div>
        <div className={styles['right-column']}>
          <CheckoutForm amount={amount} isPreview methods={methods} payee={organizationName} />
          {methods.length > 0 && <Footer hasPaymentLink />}
        </div>
      </div>
    </BrowserPreview>
  );
}
