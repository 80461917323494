import type { Model } from 'qonto/react/models/model';

export const ColumnId = {
  Description: 'description',
  Quantity: 'quantity',
  UnitPrice: 'unit_price',
  VatRate: 'vat_rate',
  Subtotal: 'subtotal',
} as const;

type ColumnIdType = (typeof ColumnId)[keyof typeof ColumnId];

export interface Column {
  id: ColumnIdType;
  label?: string;
}

export interface ReceivableInvoicesSettingsModel extends Model {
  clientAddressName?: string;
  invoiceTitle?: string;
  quoteTitle?: string;
  tableColumns?: Column[];
}
