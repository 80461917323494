import {
  IconCardOutlined,
  IconCertifiedOutlined,
  IconDocumentCheckOutlined,
} from '@repo/monochrome-icons';
import cx from 'clsx';
import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { PaymentMethodsProps } from '../payment-methods';
import styles from '../styles.strict-module.css';

type PaymentMethodsOnboardingProps = Pick<PaymentMethodsProps, 'onActivatePaymentLinksClick'>;

export function PaymentMethodsOnboarding({
  onActivatePaymentLinksClick,
}: PaymentMethodsOnboardingProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <>
      <p className="body-2" data-test-methods-description>
        <FormattedMessage id="receivable-invoices.payment-link-creation.inactive.body" />
      </p>
      <p className="body-1">
        <FormattedMessage id="receivable-invoices.payment-link-creation.inactive.header" />
      </p>

      <ul className={cx(styles['feature-list'], 'body-2')} data-test-feature-list>
        <li>
          <IconDocumentCheckOutlined height={24} width={12} />
          <FormattedMessage id="receivable-invoices.payment-link-creation.inactive.feature-1" />
        </li>
        <li>
          <IconCardOutlined height={24} width={12} />
          <FormattedMessage id="receivable-invoices.payment-link-creation.inactive.feature-2" />
        </li>
        <li>
          <IconCertifiedOutlined height={24} width={12} />
          <FormattedMessage id="receivable-invoices.payment-link-creation.inactive.feature-3" />
        </li>
      </ul>

      <p className="body-2">
        <FormattedMessage
          id="receivable-invoices.payment-link-creation.inactive.disclaimer"
          values={{
            faqUrl: (
              <a
                className="body-link"
                href={formatMessage({
                  id: 'receivable-invoices.payment-link-creation.inactive.url',
                })}
                rel="noopener noreferrer"
                target="_blank"
              >
                {formatMessage({
                  id: 'receivable-invoices.payment-link-creation.inactive.link',
                })}
              </a>
            ),
          }}
        />
      </p>

      <button
        className={cx(styles.cta, 'btn btn-secondary')}
        data-test-activate-payment-links-cta
        onClick={onActivatePaymentLinksClick}
        type="button"
      >
        <FormattedMessage id="receivable-invoices.payment-link-creation.inactive.cta" />
      </button>
    </>
  );
}
