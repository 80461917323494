import { useMutation, type UseMutateFunction } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { cashFlowCategoriesNamespace } from 'qonto/constants/hosts';
import type {
  CashflowActiveCategoryId,
  CashflowCategoryAssignment,
} from 'qonto/react/models/cash-flow-category';
import { useFetchApi } from './use-fetch-api';

interface MutationPayload extends CashflowCategoryAssignment {
  onMutate?: (categoryId: CashflowActiveCategoryId) => void;
}

export const useAssignTransactionsCategory = (): {
  mutate: UseMutateFunction<void, Error, MutationPayload>;
} => {
  const { formatMessage } = useIntl();
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const fetchApi = useFetchApi();

  const assignTransactionsCategory = async (payload: MutationPayload): Promise<void> => {
    try {
      const response = await fetchApi(
        `${cashFlowCategoriesNamespace}/cash-flow/categories/assignments`,
        {
          method: 'POST',
          body: JSON.stringify({
            transaction_ids: payload.transactionIds,
            category_id: payload.categoryId,
          }),
        }
      );

      if (!response.ok) throw Error('Category could not be saved');
    } catch (e) {
      throw new Error('Category could not be saved');
    }
  };

  const { mutate } = useMutation({
    mutationFn: assignTransactionsCategory,
    onMutate: ({ categoryId, onMutate }) => {
      onMutate?.(categoryId);
    },
    onError: () => {
      toastFlashMessages.toastError(formatMessage({ id: 'toasts.errors.server_error' }));
    },
  });

  return { mutate };
};
