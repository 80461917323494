export const ERROR_TYPES = {
  GENERIC: 'generic',
  NO_RESULT: 'no-result',
} as const;

export const AR_ADVANCED_INSTRUCTIONAL_PROMO_KEY_STORAGE = 'ar-advanced-promotional-popup';
export const AR_ADVANCED_INSTRUCTIONAL_TOOLTIP_KEY_STORAGE = 'ar-advanced-instructional-tooltip';
export const PAYMENT_LINKS_PROMO_POPUP_KEY_STORAGE = 'payment-links-promo-popup-dismissed';

export const AR_ADVANCED_INSTRUCTIONAL_TOOLTIP_END_DATE = '2025-04-01';

export const E_SIGNATURE_PROMOTIONAL_POPUP_STORAGE_KEY = 'e-signature-promo-popup-dismissed';
export const E_SIGNATURE_INSTRUCTIONAL_TOOLTIP_STORAGE_KEY =
  'e-signature-instructional-tooltip-dismissed';
export const E_SIGNATURE_CONFIRM_POPUP_STORAGE_KEY = 'e-signature-confirm-popup-dont-show-checked';
export const E_SIGNATURE_EVENT = {
  ORIGIN: 'https://yousign.app',
  TYPE: 'yousign',
};
export const E_SIGNATURE_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};
export const E_SIGNATURE_FLOW_REDIRECT = {
  QUOTES_SHOW: 'quotes.show',
  QUOTES_INDEX: 'quotes.index',
  QUOTES_SHARE: 'quotes.share',
};
