import { useQueryClient } from '@tanstack/react-query';
import { transferNamespace } from 'qonto/constants/hosts';
import { useFetchApi, type FetchFunction } from 'qonto/react/hooks/use-fetch-api';

interface Transfer {
  id: string;
}

const fetchTransfer = async (fetchApi: FetchFunction, transferId: string): Promise<Transfer> => {
  const response = await fetchApi(`${transferNamespace}/transfers/${transferId}`);

  if (!response.ok)
    throw Error(`[HTTP status: ${response.status}] Transfer ${transferId} could not be fetched`);

  const data = (await response.json()) as { transfer: Transfer };
  return data.transfer;
};

export const useFetchTransfer = (transferId: string): { prefetchTransfer: () => Promise<void> } => {
  const fetchApi = useFetchApi();
  const queryClient = useQueryClient();

  const prefetchTransfer = async (): Promise<void> => {
    await queryClient.prefetchQuery({
      queryKey: ['transfer', transferId],
      queryFn: () => fetchTransfer(fetchApi, transferId),
    });
  };

  return { prefetchTransfer };
};
